const publicurl = process.env.PUBLIC_URL;
 
export default {
  chpu_1: publicurl + '/shared/images/chpu/1.png', 
  chpu_2 : publicurl + '/shared/images/chpu/2.png', 
  chpu_3 : publicurl + '/shared/images/chpu/3.png', 
  chpu_4 : publicurl + '/shared/images/chpu/4.png', 
  chpu_5 : publicurl + '/shared/images/chpu/5.png', 
  chpu_6 : publicurl + '/shared/images/chpu/6.png', 
  chpu_7 : publicurl + '/shared/images/chpu/7.png', 
  chpu_8 : publicurl + '/shared/images/chpu/8.png', 
  chpu_9 : publicurl + '/shared/images/chpu/9.png', 
  chpu_10 : publicurl + '/shared/images/chpu/10.png',  
  chpu_11 : publicurl + '/shared/images/chpu/11.png',  
  chpu_12 : publicurl + '/shared/images/chpu/12.png',  
  chpu_13 : publicurl + '/shared/images/chpu/13.png',  
  chpu_14 : publicurl + '/shared/images/chpu/14.png',  
  chpu_15 : publicurl + '/shared/images/chpu/15.png',  
  chpu_16 : publicurl + '/shared/images/chpu/16.png',  
  chpu_17 : publicurl + '/shared/images/chpu/17.png',  
  chpu_18 : publicurl + '/shared/images/chpu/18.png',  
  chpu_19 : publicurl + '/shared/images/chpu/19.png',  
  chpu_20 : publicurl + '/shared/images/chpu/20.png',  
  chpu_21 : publicurl + '/shared/images/chpu/21.png',  
  chpu_22 : publicurl + '/shared/images/chpu/22.png',  
  chpu_23 : publicurl + '/shared/images/chpu/23.png',  
  chpu_24 : publicurl + '/shared/images/chpu/24.png',  
  chpu_25 : publicurl + '/shared/images/chpu/25.png',  
  chpu_26 : publicurl + '/shared/images/chpu/26.png',  
  chpu_27 : publicurl + '/shared/images/chpu/27.png',  
  chpu_28 : publicurl + '/shared/images/chpu/28.png',  
  chpu_29 : publicurl + '/shared/images/chpu/29.png',  
  chpu_30 : publicurl + '/shared/images/chpu/30.png',  
  chpu_31 : publicurl + '/shared/images/chpu/31.png',  
  chpu_32 : publicurl + '/shared/images/chpu/32.png',  
  chpu_33 : publicurl + '/shared/images/chpu/33.png',  
  chpu_34 : publicurl + '/shared/images/chpu/34.png',  
  chpu_35 : publicurl + '/shared/images/chpu/35.png',  
  chpu_36 : publicurl + '/shared/images/chpu/36.png',  
  chpu_37 : publicurl + '/shared/images/chpu/37.png',  
  chpu_38 : publicurl + '/shared/images/chpu/38.png',  
  chpu_39 : publicurl + '/shared/images/chpu/39.png',  
  chpu_40 : publicurl + '/shared/images/chpu/40.png',  
  chpu_41 : publicurl + '/shared/images/chpu/41.png',  
  chpu_42 : publicurl + '/shared/images/chpu/42.png',  
  chpu_43 : publicurl + '/shared/images/chpu/43.png',  
  chpu_44 : publicurl + '/shared/images/chpu/44.png',  
  chpu_45 : publicurl + '/shared/images/chpu/45.png',  
  chpu_46 : publicurl + '/shared/images/chpu/46.png',  
  chpu_47 : publicurl + '/shared/images/chpu/47.png',  
  chpu_48 : publicurl + '/shared/images/chpu/48.png',  
  chpu_49 : publicurl + '/shared/images/chpu/49.png',  
  chpu_50 : publicurl + '/shared/images/chpu/50.png',  
  chpu_51 : publicurl + '/shared/images/chpu/51.png',  
  chpu_52 : publicurl + '/shared/images/chpu/52.png',  
  chpu_53 : publicurl + '/shared/images/chpu/53.png',  
  chpu_54 : publicurl + '/shared/images/chpu/54.png',  
  chpu_55 : publicurl + '/shared/images/chpu/55.png',  
  chpu_56 : publicurl + '/shared/images/chpu/56.png',  
  chpu_57 : publicurl + '/shared/images/chpu/57.png',  
  chpu_58 : publicurl + '/shared/images/chpu/58.png',  
  chpu_59 : publicurl + '/shared/images/chpu/59.png',  
  chpu_60 : publicurl + '/shared/images/chpu/60.png',  
  chpu_61 : publicurl + '/shared/images/chpu/61.png',  
  chpu_62 : publicurl + '/shared/images/chpu/62.png',  
  chpu_63 : publicurl + '/shared/images/chpu/63.png',  
  chpu_64 : publicurl + '/shared/images/chpu/64.png',  
  chpu_65 : publicurl + '/shared/images/chpu/65.png',  
  chpu_66 : publicurl + '/shared/images/chpu/66.png',  
  chpu_67 : publicurl + '/shared/images/chpu/67.png',  
  chpu_68 : publicurl + '/shared/images/chpu/68.png',  
  chpu_69 : publicurl + '/shared/images/chpu/69.png',  
  chpu_70 : publicurl + '/shared/images/chpu/70.png',  
  chpu_71 : publicurl + '/shared/images/chpu/71.png',  
  chpu_72 : publicurl + '/shared/images/chpu/72.png',  
  chpu_73 : publicurl + '/shared/images/chpu/73.png',  
  chpu_74 : publicurl + '/shared/images/chpu/74.png',  
  chpu_75 : publicurl + '/shared/images/chpu/75.png',  
  chpu_76 : publicurl + '/shared/images/chpu/76.png',  
  chpu_77 : publicurl + '/shared/images/chpu/77.png',  
  chpu_78 : publicurl + '/shared/images/chpu/78.png',  
  chpu_79 : publicurl + '/shared/images/chpu/79.png',  
  chpu_80 : publicurl + '/shared/images/chpu/80.png',  
  chpu_81 : publicurl + '/shared/images/chpu/81.png',  
  chpu_82 : publicurl + '/shared/images/chpu/82.png',  
  chpu_83 : publicurl + '/shared/images/chpu/83.png',  
  chpu_84 : publicurl + '/shared/images/chpu/84.png',  
  chpu_85 : publicurl + '/shared/images/chpu/85.png',  
  chpu_86 : publicurl + '/shared/images/chpu/86.png',  
  chpu_87 : publicurl + '/shared/images/chpu/87.png',  
  chpu_88 : publicurl + '/shared/images/chpu/88.png',  
  chpu_89 : publicurl + '/shared/images/chpu/89.png',  
  chpu_90 : publicurl + '/shared/images/chpu/90.png',  
  chpu_91 : publicurl + '/shared/images/chpu/91.png',  
  chpu_92 : publicurl + '/shared/images/chpu/92.png',  
  chpu_93 : publicurl + '/shared/images/chpu/93.png',  
  chpu_94 : publicurl + '/shared/images/chpu/94.png',  
  chpu_95 : publicurl + '/shared/images/chpu/95.png',  
  chpu_96 : publicurl + '/shared/images/chpu/96.png',  
  chpu_97 : publicurl + '/shared/images/chpu/97.png',  
  chpu_98 : publicurl + '/shared/images/chpu/98.png',  
  chpu_99 : publicurl + '/shared/images/chpu/99.png',  
  chpu_100 : publicurl + '/shared/images/chpu/100.png',  
  chpu_101 : publicurl + '/shared/images/chpu/101.png',  
  chpu_102 : publicurl + '/shared/images/chpu/102.png',  
  chpu_103 : publicurl + '/shared/images/chpu/103.png',  
  chpu_104 : publicurl + '/shared/images/chpu/104.png',  
  chpu_105 : publicurl + '/shared/images/chpu/105.png',  
  chpu_106 : publicurl + '/shared/images/chpu/106.png',  
  chpu_107 : publicurl + '/shared/images/chpu/107.png',  
  chpu_108 : publicurl + '/shared/images/chpu/108.png',  
  chpu_109 : publicurl + '/shared/images/chpu/109.png',  
  chpu_110 : publicurl + '/shared/images/chpu/110.png',  
  chpu_111 : publicurl + '/shared/images/chpu/111.png',  
  chpu_112 : publicurl + '/shared/images/chpu/112.png',  
  chpu_113 : publicurl + '/shared/images/chpu/113.png',  
  chpu_114 : publicurl + '/shared/images/chpu/114.png',  
  chpu_115 : publicurl + '/shared/images/chpu/115.png',  
  chpu_116 : publicurl + '/shared/images/chpu/116.png',  
  chpu_117 : publicurl + '/shared/images/chpu/117.png',  
  chpu_118 : publicurl + '/shared/images/chpu/118.png',  
  chpu_119 : publicurl + '/shared/images/chpu/119.png',  
  chpu_120 : publicurl + '/shared/images/chpu/120.png',  
  chpu_121 : publicurl + '/shared/images/chpu/121.png',  
  chpu_122 : publicurl + '/shared/images/chpu/122.png',  
  chpu_123 : publicurl + '/shared/images/chpu/123.png',  
  chpu_124 : publicurl + '/shared/images/chpu/124.png',  
  chpu_125 : publicurl + '/shared/images/chpu/125.png',  
  chpu_126 : publicurl + '/shared/images/chpu/126.png',  
  chpu_127 : publicurl + '/shared/images/chpu/127.png',  
  chpu_128 : publicurl + '/shared/images/chpu/128.png',  
  chpu_129 : publicurl + '/shared/images/chpu/129.png',  
  chpu_130 : publicurl + '/shared/images/chpu/130.png',  
  chpu_131 : publicurl + '/shared/images/chpu/131.png',  
  chpu_132 : publicurl + '/shared/images/chpu/132.png',  
  chpu_133 : publicurl + '/shared/images/chpu/133.png',  
  chpu_134 : publicurl + '/shared/images/chpu/134.png',  
  chpu_135 : publicurl + '/shared/images/chpu/135.png',  
  chpu_136 : publicurl + '/shared/images/chpu/136.png',  
  chpu_137 : publicurl + '/shared/images/chpu/137.png',  
  chpu_138 : publicurl + '/shared/images/chpu/138.png',  
  chpu_139 : publicurl + '/shared/images/chpu/139.png',  
  chpu_140 : publicurl + '/shared/images/chpu/140.png',  
  chpu_141 : publicurl + '/shared/images/chpu/141.png',  
  chpu_142 : publicurl + '/shared/images/chpu/142.png',  
  chpu_143 : publicurl + '/shared/images/chpu/143.png',  
  chpu_144 : publicurl + '/shared/images/chpu/144.png',  
  chpu_145 : publicurl + '/shared/images/chpu/145.png',  
  chpu_146 : publicurl + '/shared/images/chpu/146.png',  
  chpu_147 : publicurl + '/shared/images/chpu/147.png',  
  chpu_148 : publicurl + '/shared/images/chpu/148.png',  
  chpu_149 : publicurl + '/shared/images/chpu/149.png',  
  chpu_150 : publicurl + '/shared/images/chpu/150.png',  
  chpu_151 : publicurl + '/shared/images/chpu/151.png',  
  chpu_152 : publicurl + '/shared/images/chpu/152.png',  
  chpu_153 : publicurl + '/shared/images/chpu/153.png',  
  chpu_154 : publicurl + '/shared/images/chpu/154.png',  
  chpu_155 : publicurl + '/shared/images/chpu/155.png',  
  chpu_156 : publicurl + '/shared/images/chpu/156.png',  
  chpu_157 : publicurl + '/shared/images/chpu/157.png',  
  chpu_158 : publicurl + '/shared/images/chpu/158.png',  
  chpu_159 : publicurl + '/shared/images/chpu/159.png',  
  chpu_160 : publicurl + '/shared/images/chpu/160.png',  
  chpu_161 : publicurl + '/shared/images/chpu/161.png',  
  chpu_162 : publicurl + '/shared/images/chpu/162.png',  
  chpu_163 : publicurl + '/shared/images/chpu/163.png',  
  chpu_164 : publicurl + '/shared/images/chpu/164.png',  
  chpu_165 : publicurl + '/shared/images/chpu/165.png',  
  chpu_166 : publicurl + '/shared/images/chpu/166.png',  
  chpu_167 : publicurl + '/shared/images/chpu/167.png',  
  chpu_168 : publicurl + '/shared/images/chpu/168.png',  
  chpu_169 : publicurl + '/shared/images/chpu/169.png',  
  chpu_170 : publicurl + '/shared/images/chpu/170.png',  
  chpu_171 : publicurl + '/shared/images/chpu/171.png',  
  chpu_172 : publicurl + '/shared/images/chpu/172.png',  
  chpu_173 : publicurl + '/shared/images/chpu/173.png',  
  chpu_174 : publicurl + '/shared/images/chpu/174.png',  
  chpu_175 : publicurl + '/shared/images/chpu/175.png',  
  chpu_176 : publicurl + '/shared/images/chpu/176.png',  
  chpu_177 : publicurl + '/shared/images/chpu/177.png',  
  chpu_178 : publicurl + '/shared/images/chpu/178.png',  
  chpu_179 : publicurl + '/shared/images/chpu/179.png',  
  chpu_180 : publicurl + '/shared/images/chpu/180.png',  
  chpu_181 : publicurl + '/shared/images/chpu/181.png',  
  chpu_182 : publicurl + '/shared/images/chpu/182.png',  
  chpu_183 : publicurl + '/shared/images/chpu/183.png',  
  chpu_184 : publicurl + '/shared/images/chpu/184.png',  
  chpu_185 : publicurl + '/shared/images/chpu/185.png',  
  chpu_186 : publicurl + '/shared/images/chpu/186.png',  
  chpu_187 : publicurl + '/shared/images/chpu/187.png',  
  chpu_188 : publicurl + '/shared/images/chpu/188.png',  
  chpu_189 : publicurl + '/shared/images/chpu/189.png',  
  chpu_190 : publicurl + '/shared/images/chpu/190.png',  
  chpu_191 : publicurl + '/shared/images/chpu/191.png',  
  chpu_192 : publicurl + '/shared/images/chpu/192.png',  
  chpu_193 : publicurl + '/shared/images/chpu/193.png',  
  chpu_194 : publicurl + '/shared/images/chpu/194.png',  
  chpu_195 : publicurl + '/shared/images/chpu/195.png',  
  chpu_196 : publicurl + '/shared/images/chpu/196.png',  
  chpu_197 : publicurl + '/shared/images/chpu/197.png',  
  chpu_198 : publicurl + '/shared/images/chpu/198.png',  
  chpu_199 : publicurl + '/shared/images/chpu/199.png',  
  chpu_200 : publicurl + '/shared/images/chpu/200.png',  
  chpu_201 : publicurl + '/shared/images/chpu/201.png',  
  chpu_202 : publicurl + '/shared/images/chpu/202.png',  
  chpu_203 : publicurl + '/shared/images/chpu/203.png',  
  chpu_204 : publicurl + '/shared/images/chpu/204.png',  
  chpu_205 : publicurl + '/shared/images/chpu/205.png',  
  chpu_206 : publicurl + '/shared/images/chpu/206.png',  
  chpu_207 : publicurl + '/shared/images/chpu/207.png',  
  chpu_208 : publicurl + '/shared/images/chpu/208.png',  
  chpu_209 : publicurl + '/shared/images/chpu/209.png',  
  chpu_210 : publicurl + '/shared/images/chpu/210.png',  
  chpu_211 : publicurl + '/shared/images/chpu/211.png',  
  chpu_212 : publicurl + '/shared/images/chpu/212.png',  
  chpu_213 : publicurl + '/shared/images/chpu/213.png',  
  chpu_214 : publicurl + '/shared/images/chpu/214.png',  
  chpu_215 : publicurl + '/shared/images/chpu/215.png',  
  chpu_216 : publicurl + '/shared/images/chpu/216.png',  
  chpu_217 : publicurl + '/shared/images/chpu/217.png',  
  chpu_218 : publicurl + '/shared/images/chpu/218.png',  
  chpu_219 : publicurl + '/shared/images/chpu/219.png',  
  chpu_220 : publicurl + '/shared/images/chpu/220.png',  
  chpu_221 : publicurl + '/shared/images/chpu/221.png',  
  chpu_222 : publicurl + '/shared/images/chpu/222.png',  
  chpu_223 : publicurl + '/shared/images/chpu/223.png',  
  chpu_224 : publicurl + '/shared/images/chpu/224.png',  
  chpu_225 : publicurl + '/shared/images/chpu/225.png',  
  chpu_226 : publicurl + '/shared/images/chpu/226.png',  
  chpu_227 : publicurl + '/shared/images/chpu/227.png',  
  chpu_228 : publicurl + '/shared/images/chpu/228.png',  
  chpu_229 : publicurl + '/shared/images/chpu/229.png',  
  chpu_230 : publicurl + '/shared/images/chpu/230.png',  
  chpu_231 : publicurl + '/shared/images/chpu/231.png',  
  chpu_232 : publicurl + '/shared/images/chpu/232.png',  
  chpu_233 : publicurl + '/shared/images/chpu/233.png',  
  chpu_234 : publicurl + '/shared/images/chpu/234.png',  
  chpu_235 : publicurl + '/shared/images/chpu/235.png',  
  chpu_236 : publicurl + '/shared/images/chpu/236.png',  
  chpu_237 : publicurl + '/shared/images/chpu/237.png',  
  chpu_238 : publicurl + '/shared/images/chpu/238.png',  
  chpu_239 : publicurl + '/shared/images/chpu/239.png',  
  chpu_240 : publicurl + '/shared/images/chpu/240.png',  
  chpu_241 : publicurl + '/shared/images/chpu/241.png',  
  chpu_242 : publicurl + '/shared/images/chpu/242.png',  
  chpu_243 : publicurl + '/shared/images/chpu/243.png',  
  chpu_244 : publicurl + '/shared/images/chpu/244.png',  
  chpu_245 : publicurl + '/shared/images/chpu/245.png',  
  chpu_246 : publicurl + '/shared/images/chpu/246.png',  
  chpu_247 : publicurl + '/shared/images/chpu/247.png',  
  chpu_248 : publicurl + '/shared/images/chpu/248.png',  
  chpu_249 : publicurl + '/shared/images/chpu/249.png',  
  chpu_250 : publicurl + '/shared/images/chpu/250.png',  
  chpu_251 : publicurl + '/shared/images/chpu/251.png',  
  chpu_252 : publicurl + '/shared/images/chpu/252.png',  
  chpu_253 : publicurl + '/shared/images/chpu/253.png',  
  chpu_254 : publicurl + '/shared/images/chpu/254.png',  
  chpu_255 : publicurl + '/shared/images/chpu/255.png',  
  chpu_256 : publicurl + '/shared/images/chpu/256.png',  
  chpu_257 : publicurl + '/shared/images/chpu/257.png',  
  chpu_258 : publicurl + '/shared/images/chpu/258.png',  
  chpu_259 : publicurl + '/shared/images/chpu/259.png',  
  chpu_260 : publicurl + '/shared/images/chpu/260.png',  
  chpu_261 : publicurl + '/shared/images/chpu/261.png',  
  chpu_262 : publicurl + '/shared/images/chpu/262.png',  
  chpu_263 : publicurl + '/shared/images/chpu/263.png',  
  chpu_264 : publicurl + '/shared/images/chpu/264.png',  
  chpu_265 : publicurl + '/shared/images/chpu/265.png',  
  chpu_266 : publicurl + '/shared/images/chpu/266.png',  
  chpu_267 : publicurl + '/shared/images/chpu/267.png',  
  chpu_268 : publicurl + '/shared/images/chpu/268.png',  
  chpu_269 : publicurl + '/shared/images/chpu/269.png',  
  chpu_270 : publicurl + '/shared/images/chpu/270.png',  
  chpu_271 : publicurl + '/shared/images/chpu/271.png',  
  chpu_272 : publicurl + '/shared/images/chpu/272.png',  
  chpu_273 : publicurl + '/shared/images/chpu/273.png',  
  chpu_274 : publicurl + '/shared/images/chpu/274.png',  
  chpu_275 : publicurl + '/shared/images/chpu/275.png',  
  chpu_276 : publicurl + '/shared/images/chpu/276.png',  
  chpu_277 : publicurl + '/shared/images/chpu/277.png',  
  chpu_278 : publicurl + '/shared/images/chpu/278.png',  
  chpu_279 : publicurl + '/shared/images/chpu/279.png',  
  chpu_280 : publicurl + '/shared/images/chpu/280.png',  
  chpu_281 : publicurl + '/shared/images/chpu/281.png',  
  chpu_282 : publicurl + '/shared/images/chpu/282.png',  
  chpu_283 : publicurl + '/shared/images/chpu/283.png',  
  chpu_284 : publicurl + '/shared/images/chpu/284.png',  
  chpu_285 : publicurl + '/shared/images/chpu/285.png',  
  chpu_286 : publicurl + '/shared/images/chpu/286.png',  
  chpu_287 : publicurl + '/shared/images/chpu/287.png',  
  chpu_288 : publicurl + '/shared/images/chpu/288.png',  
  chpu_289 : publicurl + '/shared/images/chpu/289.png',  
  chpu_290 : publicurl + '/shared/images/chpu/290.png',  
  chpu_291 : publicurl + '/shared/images/chpu/291.png',  
  chpu_292 : publicurl + '/shared/images/chpu/292.png',  
  chpu_293 : publicurl + '/shared/images/chpu/293.png',  
  chpu_294 : publicurl + '/shared/images/chpu/294.png',  
  chpu_295 : publicurl + '/shared/images/chpu/295.png',  
  chpu_296 : publicurl + '/shared/images/chpu/296.png',  
  chpu_297 : publicurl + '/shared/images/chpu/297.png',  
  chpu_298 : publicurl + '/shared/images/chpu/298.png',  
  chpu_299 : publicurl + '/shared/images/chpu/299.png',  
  chpu_300 : publicurl + '/shared/images/chpu/300.png',  
  chpu_301 : publicurl + '/shared/images/chpu/301.png',  
  chpu_302 : publicurl + '/shared/images/chpu/302.png',  
  chpu_303 : publicurl + '/shared/images/chpu/303.png',  
  chpu_304 : publicurl + '/shared/images/chpu/304.png',  
  chpu_305 : publicurl + '/shared/images/chpu/305.png',  
  chpu_306 : publicurl + '/shared/images/chpu/306.png',  
  chpu_307 : publicurl + '/shared/images/chpu/307.png',  
  chpu_308 : publicurl + '/shared/images/chpu/308.png',  
  chpu_309 : publicurl + '/shared/images/chpu/309.png',  
  chpu_310 : publicurl + '/shared/images/chpu/310.png',  
  chpu_311 : publicurl + '/shared/images/chpu/311.png',  
  chpu_312 : publicurl + '/shared/images/chpu/312.png',  
  chpu_313 : publicurl + '/shared/images/chpu/313.png',  
  chpu_314 : publicurl + '/shared/images/chpu/314.png',  
  chpu_315 : publicurl + '/shared/images/chpu/315.png',  
  chpu_316 : publicurl + '/shared/images/chpu/316.png',  
  chpu_317 : publicurl + '/shared/images/chpu/317.png',  
  chpu_318 : publicurl + '/shared/images/chpu/318.png',  
  chpu_319 : publicurl + '/shared/images/chpu/319.png',  
  chpu_320 : publicurl + '/shared/images/chpu/320.png',  
  chpu_321 : publicurl + '/shared/images/chpu/321.png',  
  chpu_322 : publicurl + '/shared/images/chpu/322.png',  
  kids_1 : publicurl + '/shared/images/kids/1.png',  
  kids_2 : publicurl + '/shared/images/kids/2.png',  
  kids_3 : publicurl + '/shared/images/kids/3.png',  
  kids_4 : publicurl + '/shared/images/kids/4.png',  
  kids_5 : publicurl + '/shared/images/kids/5.png',  
  kids_6 : publicurl + '/shared/images/kids/6.png',  
  kids_7 : publicurl + '/shared/images/kids/7.png',  
  kids_8 : publicurl + '/shared/images/kids/8.png',  
  kids_9 : publicurl + '/shared/images/kids/9.png',  
  kids_10 : publicurl + '/shared/images/kids/10.png',  
  kids_11 : publicurl + '/shared/images/kids/11.png',  
  kids_12 : publicurl + '/shared/images/kids/12.png',  
  kids_13 : publicurl + '/shared/images/kids/13.png',  
  kids_14 : publicurl + '/shared/images/kids/14.png',  
  kids_15 : publicurl + '/shared/images/kids/15.png',  
  kids_16 : publicurl + '/shared/images/kids/16.png',  
  kids_17 : publicurl + '/shared/images/kids/17.png',  
  kids_18 : publicurl + '/shared/images/kids/18.png',  
  kids_19 : publicurl + '/shared/images/kids/19.png',  
  kids_20 : publicurl + '/shared/images/kids/20.png',  
  kids_21 : publicurl + '/shared/images/kids/21.png',  
  kids_22 : publicurl + '/shared/images/kids/22.png',  
  kids_23 : publicurl + '/shared/images/kids/23.png',  
  kids_24 : publicurl + '/shared/images/kids/24.png',  
  kids_25 : publicurl + '/shared/images/kids/25.png',  
  kids_26 : publicurl + '/shared/images/kids/26.png',  
  kids_27 : publicurl + '/shared/images/kids/27.png',  
  kids_28 : publicurl + '/shared/images/kids/28.png',  
  kids_29 : publicurl + '/shared/images/kids/29.png',  
  kids_30 : publicurl + '/shared/images/kids/30.png',  
  kids_31 : publicurl + '/shared/images/kids/31.png',    
  gvert_1 : publicurl + '/shared/images/gvert/1.png',    
  gvert_2 : publicurl + '/shared/images/gvert/2.png',    
  gvert_3 : publicurl + '/shared/images/gvert/3.png',    
  gvert_4 : publicurl + '/shared/images/gvert/4.png',    
  gvert_5 : publicurl + '/shared/images/gvert/5.png',    
  gvert_6 : publicurl + '/shared/images/gvert/6.png',    
  gvert_7 : publicurl + '/shared/images/gvert/7.png',    
  gvert_8 : publicurl + '/shared/images/gvert/8.png',    
  gvert_9 : publicurl + '/shared/images/gvert/9.png',    
  gvert_10 : publicurl + '/shared/images/gvert/10.png',    
  gvert_11 : publicurl + '/shared/images/gvert/11.png',    
  gvert_12 : publicurl + '/shared/images/gvert/12.png',    
  gvert_13 : publicurl + '/shared/images/gvert/13.png',    
  gvert_14 : publicurl + '/shared/images/gvert/14.png',    
  gvert_15 : publicurl + '/shared/images/gvert/15.png',    
  gvert_16 : publicurl + '/shared/images/gvert/16.png',    
  gvert_17 : publicurl + '/shared/images/gvert/17.png',    
  gvert_18 : publicurl + '/shared/images/gvert/18.png',    
  gvert_19 : publicurl + '/shared/images/gvert/19.png',    
  gvert_20 : publicurl + '/shared/images/gvert/20.png',    
  gvert_21 : publicurl + '/shared/images/gvert/21.png',    
  gvert_22 : publicurl + '/shared/images/gvert/22.png',    
  gvert_23 : publicurl + '/shared/images/gvert/23.png',    
  gvert_24 : publicurl + '/shared/images/gvert/24.png',    
  gvert_25 : publicurl + '/shared/images/gvert/25.png',    
  gvert_26 : publicurl + '/shared/images/gvert/26.png',    
  gvert_27 : publicurl + '/shared/images/gvert/27.png',    
  gvert_28 : publicurl + '/shared/images/gvert/28.png',    
  gvert_29 : publicurl + '/shared/images/gvert/29.png',    
  gvert_30 : publicurl + '/shared/images/gvert/30.png',    
  gvert_31 : publicurl + '/shared/images/gvert/31.png',    
  gvert_32 : publicurl + '/shared/images/gvert/32.png',    
  gvert_33 : publicurl + '/shared/images/gvert/33.png',    
  gvert_34 : publicurl + '/shared/images/gvert/34.png',    
  gvert_35 : publicurl + '/shared/images/gvert/35.png',    
  gvert_36 : publicurl + '/shared/images/gvert/36.png',    
  gvert_37 : publicurl + '/shared/images/gvert/37.png',    
  gvert_38 : publicurl + '/shared/images/gvert/38.png',    
  gvert_39 : publicurl + '/shared/images/gvert/39.png',    
  gvert_40 : publicurl + '/shared/images/gvert/40.png',    
  gvert_41 : publicurl + '/shared/images/gvert/41.png',    
  gvert_42 : publicurl + '/shared/images/gvert/42.png',    
  gvert_43 : publicurl + '/shared/images/gvert/43.png',    
  gvert_44 : publicurl + '/shared/images/gvert/44.png',    
  gvert_45 : publicurl + '/shared/images/gvert/45.png',    
  gvert_46 : publicurl + '/shared/images/gvert/46.png',    
  gvert_47 : publicurl + '/shared/images/gvert/47.png',    
  gvert_48 : publicurl + '/shared/images/gvert/48.png',    
  gvert_49 : publicurl + '/shared/images/gvert/49.png',    
  gvert_50 : publicurl + '/shared/images/gvert/50.png',    
  gvert_51 : publicurl + '/shared/images/gvert/51.png',    
  gvert_52 : publicurl + '/shared/images/gvert/52.png',    
  gvert_53 : publicurl + '/shared/images/gvert/53.png',    
  gvert_54a : publicurl + '/shared/images/gvert/54a.png',    
  gvert_54b : publicurl + '/shared/images/gvert/54b.png',    
  gvert_54c : publicurl + '/shared/images/gvert/54c.png',    
  gvert_54d : publicurl + '/shared/images/gvert/54d.png',    
  gvert_54e : publicurl + '/shared/images/gvert/54e.png',      
  gvert_55 : publicurl + '/shared/images/gvert/55.png',    
  gvert_56 : publicurl + '/shared/images/gvert/56.png',    
  gvert_57 : publicurl + '/shared/images/gvert/57.png',    
  gvert_58 : publicurl + '/shared/images/gvert/58.png',    
  gvert_59 : publicurl + '/shared/images/gvert/59.png',    
  gvert_60 : publicurl + '/shared/images/gvert/60.png',    
  gvert_61 : publicurl + '/shared/images/gvert/61.png',    
  gvert_62 : publicurl + '/shared/images/gvert/62.png',    
  gvert_63 : publicurl + '/shared/images/gvert/63.png',    
  gvert_64 : publicurl + '/shared/images/gvert/64.png',    
  gvert_65 : publicurl + '/shared/images/gvert/65.png',    
  gvert_66 : publicurl + '/shared/images/gvert/66.png',    
  gvert_67 : publicurl + '/shared/images/gvert/67.png',    
  gvert_68 : publicurl + '/shared/images/gvert/68.png',    
  gvert_69 : publicurl + '/shared/images/gvert/69.png',    
  gvert_70 : publicurl + '/shared/images/gvert/70.png',    
  gvert_71 : publicurl + '/shared/images/gvert/71.png',    
  gvert_72 : publicurl + '/shared/images/gvert/72.png',    
  gvert_73 : publicurl + '/shared/images/gvert/73.png',    
  gvert_74 : publicurl + '/shared/images/gvert/74.png',    
  gvert_75 : publicurl + '/shared/images/gvert/75.png',    
  gvert_76 : publicurl + '/shared/images/gvert/76.png',    
  gvert_77 : publicurl + '/shared/images/gvert/77.png',    
  gvert_78 : publicurl + '/shared/images/gvert/78.png',    
  gvert_79 : publicurl + '/shared/images/gvert/79.png',    
  gvert_80 : publicurl + '/shared/images/gvert/80.png',    
  gvert_81 : publicurl + '/shared/images/gvert/81.png',    
  gvert_82 : publicurl + '/shared/images/gvert/82.png',    
  gvert_83 : publicurl + '/shared/images/gvert/83.png',    
  gvert_84 : publicurl + '/shared/images/gvert/84.png',    
  gvert_85 : publicurl + '/shared/images/gvert/85.png',    
  gvert_86 : publicurl + '/shared/images/gvert/86.png',    
  gvert_87 : publicurl + '/shared/images/gvert/87.png',        
  gvert_88 : publicurl + '/shared/images/gvert/88.jpg',        
  gvert_89 : publicurl + '/shared/images/gvert/89.png',    
  gvert_90 : publicurl + '/shared/images/gvert/90.png',    
  gvert_91 : publicurl + '/shared/images/gvert/91.png',    
  gvert_92 : publicurl + '/shared/images/gvert/92.png',    
  gvert_93 : publicurl + '/shared/images/gvert/93.png',    
  gvert_94 : publicurl + '/shared/images/gvert/94.png',    
  gvert_95 : publicurl + '/shared/images/gvert/95.png',    
  gvert_96 : publicurl + '/shared/images/gvert/96.png',    
  gvert_97 : publicurl + '/shared/images/gvert/97.png',    
  gvert_98 : publicurl + '/shared/images/gvert/98.png',    
  gvert_99 : publicurl + '/shared/images/gvert/99.png',    
  gvert_100 : publicurl + '/shared/images/gvert/100.png',    
  gvert_101 : publicurl + '/shared/images/gvert/101.png',    
  gvert_102 : publicurl + '/shared/images/gvert/102.png',    
  gvert_103 : publicurl + '/shared/images/gvert/103.png',    
  gvert_104 : publicurl + '/shared/images/gvert/104.png',    
  gvert_105 : publicurl + '/shared/images/gvert/105.png',    
  gvert_106 : publicurl + '/shared/images/gvert/106.png',    
  gvert_107 : publicurl + '/shared/images/gvert/107.png',    
  gvert_108 : publicurl + '/shared/images/gvert/108.png',    
  gvert_109 : publicurl + '/shared/images/gvert/109.png',    
  gvert_110 : publicurl + '/shared/images/gvert/110.png',    
  gvert_111 : publicurl + '/shared/images/gvert/111.png',    
  gvert_112 : publicurl + '/shared/images/gvert/112.png',    
  gvert_113 : publicurl + '/shared/images/gvert/113.png',    
  gvert_114 : publicurl + '/shared/images/gvert/114.png',    
  gvert_115 : publicurl + '/shared/images/gvert/115.png',    
  gvert_116 : publicurl + '/shared/images/gvert/116.png',    
  gvert_117 : publicurl + '/shared/images/gvert/117.png',    
  gvert_118 : publicurl + '/shared/images/gvert/118.png',    
  gvert_119 : publicurl + '/shared/images/gvert/119.png',    
  gvert_120 : publicurl + '/shared/images/gvert/120.png',    
  gvert_121 : publicurl + '/shared/images/gvert/121.png',    
  gvert_122 : publicurl + '/shared/images/gvert/122.png',    
  gvert_123 : publicurl + '/shared/images/gvert/123.png',    
  gvert_124 : publicurl + '/shared/images/gvert/124.png',    
  gvert_125 : publicurl + '/shared/images/gvert/125.png',    
  gvert_126 : publicurl + '/shared/images/gvert/126.png',    
  gvert_127 : publicurl + '/shared/images/gvert/127.png',        
  gvert_128 : publicurl + '/shared/images/gvert/128.jpg',        
  gvert_129 : publicurl + '/shared/images/gvert/129.png',    
  gvert_130 : publicurl + '/shared/images/gvert/130.png',    
  gvert_131 : publicurl + '/shared/images/gvert/131.png',    
  gvert_132 : publicurl + '/shared/images/gvert/132.png',    
  gvert_133 : publicurl + '/shared/images/gvert/133.png',    
  gvert_134 : publicurl + '/shared/images/gvert/134.png',    
  gvert_135 : publicurl + '/shared/images/gvert/135.png',    
  gvert_136 : publicurl + '/shared/images/gvert/136.png',    
  gvert_137 : publicurl + '/shared/images/gvert/137.png',    
  gvert_138 : publicurl + '/shared/images/gvert/138.png',    
  gvert_139 : publicurl + '/shared/images/gvert/139.png',    
  gvert_140 : publicurl + '/shared/images/gvert/140.png',    
  gvert_141 : publicurl + '/shared/images/gvert/141.png',    
  gvert_142 : publicurl + '/shared/images/gvert/142.png',    
  gvert_143 : publicurl + '/shared/images/gvert/143.png',    
  gvert_144 : publicurl + '/shared/images/gvert/144.png',    
  gvert_145 : publicurl + '/shared/images/gvert/145.png',    
  gvert_146 : publicurl + '/shared/images/gvert/146.png',    
  gvert_147 : publicurl + '/shared/images/gvert/147.png',    
  gvert_148 : publicurl + '/shared/images/gvert/148.png',    
  gvert_149 : publicurl + '/shared/images/gvert/149.png',    
  gvert_150 : publicurl + '/shared/images/gvert/150.png',    
  gvert_151 : publicurl + '/shared/images/gvert/151.png',    
  gvert_152 : publicurl + '/shared/images/gvert/152.png',    
  gvert_153 : publicurl + '/shared/images/gvert/153.png',    
  gvert_154 : publicurl + '/shared/images/gvert/154.png',    
  gvert_155 : publicurl + '/shared/images/gvert/155.png',    
  gvert_156 : publicurl + '/shared/images/gvert/156.png',    
  gvert_157 : publicurl + '/shared/images/gvert/157.png',    
  gvert_158 : publicurl + '/shared/images/gvert/158.png',    
  gvert_159 : publicurl + '/shared/images/gvert/159.png',        
  ggoriz_1 : publicurl + '/shared/images/ggoriz/1.png',    
  ggoriz_2 : publicurl + '/shared/images/ggoriz/2.png',    
  ggoriz_3 : publicurl + '/shared/images/ggoriz/3.png',    
  ggoriz_4 : publicurl + '/shared/images/ggoriz/4.png',    
  ggoriz_5 : publicurl + '/shared/images/ggoriz/5.png',    
  ggoriz_6 : publicurl + '/shared/images/ggoriz/6.png',    
  ggoriz_7 : publicurl + '/shared/images/ggoriz/7.png',    
  ggoriz_8 : publicurl + '/shared/images/ggoriz/8.png',    
  ggoriz_9 : publicurl + '/shared/images/ggoriz/9.png',    
  ggoriz_10 : publicurl + '/shared/images/ggoriz/10.png',    
  ggoriz_11 : publicurl + '/shared/images/ggoriz/11.png',    
  ggoriz_12 : publicurl + '/shared/images/ggoriz/12.png',    
  ggoriz_13 : publicurl + '/shared/images/ggoriz/13.png',    
  ggoriz_14 : publicurl + '/shared/images/ggoriz/14.png',    
  ggoriz_15 : publicurl + '/shared/images/ggoriz/15.png',    
  ggoriz_16 : publicurl + '/shared/images/ggoriz/16.png',    
  ggoriz_17 : publicurl + '/shared/images/ggoriz/17.png',    
  ggoriz_18 : publicurl + '/shared/images/ggoriz/18.png',    
  ggoriz_19 : publicurl + '/shared/images/ggoriz/19.png',    
  ggoriz_20 : publicurl + '/shared/images/ggoriz/20.png',    
  ggoriz_21 : publicurl + '/shared/images/ggoriz/21.png',    
  ggoriz_22 : publicurl + '/shared/images/ggoriz/22.png',    
  ggoriz_23 : publicurl + '/shared/images/ggoriz/23.png',    
  ggoriz_24 : publicurl + '/shared/images/ggoriz/24.png',    
  ggoriz_25 : publicurl + '/shared/images/ggoriz/25.png',    
  ggoriz_26 : publicurl + '/shared/images/ggoriz/26.png',    
  ggoriz_27 : publicurl + '/shared/images/ggoriz/27.png',    
  ggoriz_28 : publicurl + '/shared/images/ggoriz/28.png',    
  ggoriz_29 : publicurl + '/shared/images/ggoriz/29.png',    
  ggoriz_30 : publicurl + '/shared/images/ggoriz/30.png',    
  ggoriz_31 : publicurl + '/shared/images/ggoriz/31.png',    
  ggoriz_32 : publicurl + '/shared/images/ggoriz/32.png',    
  ggoriz_33 : publicurl + '/shared/images/ggoriz/33.png',    
  ggoriz_34 : publicurl + '/shared/images/ggoriz/34.png',    
  ggoriz_35 : publicurl + '/shared/images/ggoriz/35.png',    
  ggoriz_36 : publicurl + '/shared/images/ggoriz/36.png',    
  ggoriz_37 : publicurl + '/shared/images/ggoriz/37.png',    
  ggoriz_38 : publicurl + '/shared/images/ggoriz/38.png',    
  ggoriz_39 : publicurl + '/shared/images/ggoriz/39.png',    
  ggoriz_40 : publicurl + '/shared/images/ggoriz/40.png',    
  ggoriz_41 : publicurl + '/shared/images/ggoriz/41.png',    
  ggoriz_42 : publicurl + '/shared/images/ggoriz/42.png',    
  ggoriz_43 : publicurl + '/shared/images/ggoriz/43.png',    
  ggoriz_44 : publicurl + '/shared/images/ggoriz/44.png',    
  ggoriz_45 : publicurl + '/shared/images/ggoriz/45.png',    
  ggoriz_46 : publicurl + '/shared/images/ggoriz/46.png',    
  ggoriz_47 : publicurl + '/shared/images/ggoriz/47.png',    
  ggoriz_48 : publicurl + '/shared/images/ggoriz/48.png',    
  ggoriz_49 : publicurl + '/shared/images/ggoriz/49.png',    
  ggoriz_50 : publicurl + '/shared/images/ggoriz/50.png',    
  ggoriz_51 : publicurl + '/shared/images/ggoriz/51.png',    
  ggoriz_52 : publicurl + '/shared/images/ggoriz/52.png',    
  ggoriz_53 : publicurl + '/shared/images/ggoriz/53.png',    
  ggoriz_54 : publicurl + '/shared/images/ggoriz/54.png',    
  ggoriz_55 : publicurl + '/shared/images/ggoriz/55.png',    
  ggoriz_56 : publicurl + '/shared/images/ggoriz/56.png',    
  ggoriz_57 : publicurl + '/shared/images/ggoriz/57.png',    
  ggoriz_58 : publicurl + '/shared/images/ggoriz/58.png',    
  ggoriz_59 : publicurl + '/shared/images/ggoriz/59.png',    
  ggoriz_60 : publicurl + '/shared/images/ggoriz/60.png',    
  ggoriz_61 : publicurl + '/shared/images/ggoriz/61.png',    
  ggoriz_62 : publicurl + '/shared/images/ggoriz/62.png',    
  ggoriz_63 : publicurl + '/shared/images/ggoriz/63.png',    
  ggoriz_64 : publicurl + '/shared/images/ggoriz/64.png',    
  ggoriz_65 : publicurl + '/shared/images/ggoriz/65.png',    
  ggoriz_66 : publicurl + '/shared/images/ggoriz/66.png',    
  ggoriz_67 : publicurl + '/shared/images/ggoriz/67.png',    
  ggoriz_68 : publicurl + '/shared/images/ggoriz/68.png',    
  ggoriz_69 : publicurl + '/shared/images/ggoriz/69.png',    
  ggoriz_70 : publicurl + '/shared/images/ggoriz/70.png',    
  ggoriz_71 : publicurl + '/shared/images/ggoriz/71.png',    
  ggoriz_72 : publicurl + '/shared/images/ggoriz/72.png',    
  ggoriz_73 : publicurl + '/shared/images/ggoriz/73.png',    
  ggoriz_74 : publicurl + '/shared/images/ggoriz/74.png',    
  ggoriz_75 : publicurl + '/shared/images/ggoriz/75.png',    
  ggoriz_76 : publicurl + '/shared/images/ggoriz/76.png',    
  ggoriz_77 : publicurl + '/shared/images/ggoriz/77.png',    
  ggoriz_78 : publicurl + '/shared/images/ggoriz/78.png',    
  ggoriz_79 : publicurl + '/shared/images/ggoriz/79.png',    
  ggoriz_80 : publicurl + '/shared/images/ggoriz/80.png',    
  ggoriz_81 : publicurl + '/shared/images/ggoriz/81.png',  
  mgoriz_1 : publicurl + '/shared/images/mgoriz/1.png',      
  mgoriz_2 : publicurl + '/shared/images/mgoriz/2.png',      
  mgoriz_3 : publicurl + '/shared/images/mgoriz/3.png',      
  mgoriz_4 : publicurl + '/shared/images/mgoriz/4.png',      
  mgoriz_5 : publicurl + '/shared/images/mgoriz/5.png',      
  mgoriz_6 : publicurl + '/shared/images/mgoriz/6.png',      
  mgoriz_7 : publicurl + '/shared/images/mgoriz/7.png',      
  mgoriz_8 : publicurl + '/shared/images/mgoriz/8.png',      
  mgoriz_9 : publicurl + '/shared/images/mgoriz/9.png',      
  mgoriz_10 : publicurl + '/shared/images/mgoriz/10.png',      
  mgoriz_11 : publicurl + '/shared/images/mgoriz/11.png',      
  mgoriz_12 : publicurl + '/shared/images/mgoriz/12.png',      
  mgoriz_13 : publicurl + '/shared/images/mgoriz/13.png',      
  mgoriz_14 : publicurl + '/shared/images/mgoriz/14.png',      
  mgoriz_15 : publicurl + '/shared/images/mgoriz/15.png',      
  mgoriz_16 : publicurl + '/shared/images/mgoriz/16.png',      
  mgoriz_17 : publicurl + '/shared/images/mgoriz/17.png',      
  mgoriz_18 : publicurl + '/shared/images/mgoriz/18.png',      
  mgoriz_19 : publicurl + '/shared/images/mgoriz/19.png',      
  mgoriz_20 : publicurl + '/shared/images/mgoriz/20.png',      
  mgoriz_21 : publicurl + '/shared/images/mgoriz/21.png',      
  mgoriz_22 : publicurl + '/shared/images/mgoriz/22.png',      
  mgoriz_23 : publicurl + '/shared/images/mgoriz/23.png',      
  mgoriz_24 : publicurl + '/shared/images/mgoriz/24.png', 
  mvert_1 : publicurl + '/shared/images/mvert/1.png',      
  mvert_2 : publicurl + '/shared/images/mvert/2.png',      
  mvert_3 : publicurl + '/shared/images/mvert/3.png',      
  mvert_4 : publicurl + '/shared/images/mvert/4.png',      
  mvert_5 : publicurl + '/shared/images/mvert/5.png',      
  mvert_6 : publicurl + '/shared/images/mvert/6.png',      
  mvert_7 : publicurl + '/shared/images/mvert/7.png',      
  mvert_8 : publicurl + '/shared/images/mvert/8.png',      
  mvert_9 : publicurl + '/shared/images/mvert/9.png',      
  mvert_10 : publicurl + '/shared/images/mvert/10.png',      
  mvert_11 : publicurl + '/shared/images/mvert/11.png',      
  mvert_12 : publicurl + '/shared/images/mvert/12.png',      
  mvert_13 : publicurl + '/shared/images/mvert/13.png',      
  mvert_14 : publicurl + '/shared/images/mvert/14.png',      
  mvert_15 : publicurl + '/shared/images/mvert/15.png',      
  mvert_16 : publicurl + '/shared/images/mvert/16.png',      
  mvert_17 : publicurl + '/shared/images/mvert/17.png',      
  mvert_18 : publicurl + '/shared/images/mvert/18.png',      
  mvert_19 : publicurl + '/shared/images/mvert/19.png',      
  mvert_20 : publicurl + '/shared/images/mvert/20.png',      
  mvert_21 : publicurl + '/shared/images/mvert/21.png',      
  mvert_22 : publicurl + '/shared/images/mvert/22.png',      
  mvert_23 : publicurl + '/shared/images/mvert/23.png',      
  mvert_24 : publicurl + '/shared/images/mvert/24.png',      
  mvert_25 : publicurl + '/shared/images/mvert/25.png',      
  mvert_26 : publicurl + '/shared/images/mvert/26.png',      
  mvert_27 : publicurl + '/shared/images/mvert/27.png',      
  mvert_28 : publicurl + '/shared/images/mvert/28.png',      
  mvert_29 : publicurl + '/shared/images/mvert/29.png',      
  mvert_30 : publicurl + '/shared/images/mvert/30.png',      
  mvert_31 : publicurl + '/shared/images/mvert/31.png',      
  mvert_32 : publicurl + '/shared/images/mvert/32.png',      
  mvert_33 : publicurl + '/shared/images/mvert/33.png',      
  mvert_34 : publicurl + '/shared/images/mvert/34.png',      
  mvert_35 : publicurl + '/shared/images/mvert/35.png',      
  mvert_36 : publicurl + '/shared/images/mvert/36.png',      
  mvert_37 : publicurl + '/shared/images/mvert/37.png',      
  mvert_38 : publicurl + '/shared/images/mvert/38.png',      
  mvert_39 : publicurl + '/shared/images/mvert/39.png',      
  mvert_40 : publicurl + '/shared/images/mvert/40.png',      
  mvert_41 : publicurl + '/shared/images/mvert/41.png',      
  mvert_42 : publicurl + '/shared/images/mvert/42.png',      
  mvert_43 : publicurl + '/shared/images/mvert/43.png',      
  mvert_44 : publicurl + '/shared/images/mvert/44.png',      
  mvert_45 : publicurl + '/shared/images/mvert/45.png',      
  mvert_46 : publicurl + '/shared/images/mvert/46.png',      
  mvert_47 : publicurl + '/shared/images/mvert/47.png',      
  mvert_48 : publicurl + '/shared/images/mvert/48.png',      
  mvert_49 : publicurl + '/shared/images/mvert/49.png',      
  mvert_50 : publicurl + '/shared/images/mvert/50.png',      
  mvert_51 : publicurl + '/shared/images/mvert/51.png',      
  mvert_52 : publicurl + '/shared/images/mvert/52.png',      
  mvert_53 : publicurl + '/shared/images/mvert/53.png',      
  mvert_54 : publicurl + '/shared/images/mvert/54.png',      
  mvert_55 : publicurl + '/shared/images/mvert/55.png',      
  mvert_56 : publicurl + '/shared/images/mvert/56.png',      
  mvert_57 : publicurl + '/shared/images/mvert/57.png',      
  mvert_58 : publicurl + '/shared/images/mvert/58.png',      
  mvert_59 : publicurl + '/shared/images/mvert/59.png',      
  mvert_60 : publicurl + '/shared/images/mvert/60.png',      
  mvert_61 : publicurl + '/shared/images/mvert/61.png',      
  mvert_62 : publicurl + '/shared/images/mvert/62.png',      
  mvert_63 : publicurl + '/shared/images/mvert/63.png',      
  mvert_64 : publicurl + '/shared/images/mvert/64.png',      
  mvert_65 : publicurl + '/shared/images/mvert/65.png',      
  mvert_66 : publicurl + '/shared/images/mvert/66.png',      
  mvert_67 : publicurl + '/shared/images/mvert/67.png',      
  mvert_68 : publicurl + '/shared/images/mvert/68.png',      
  mvert_69 : publicurl + '/shared/images/mvert/69.png',      
  mvert_70 : publicurl + '/shared/images/mvert/70.png',      
  mvert_71 : publicurl + '/shared/images/mvert/71.png',      
  mvert_72 : publicurl + '/shared/images/mvert/72.png',      
  mvert_73 : publicurl + '/shared/images/mvert/73.png',      
  mvert_74 : publicurl + '/shared/images/mvert/74.png',      
  mvert_75 : publicurl + '/shared/images/mvert/75.png',      
  mvert_76 : publicurl + '/shared/images/mvert/76.png',      
  mvert_77 : publicurl + '/shared/images/mvert/77.png',      
  mvert_78 : publicurl + '/shared/images/mvert/78.png',      
  mvert_79 : publicurl + '/shared/images/mvert/79.png',      
  mvert_80 : publicurl + '/shared/images/mvert/80.png',      
  mvert_81 : publicurl + '/shared/images/mvert/81.png',      
  mvert_82 : publicurl + '/shared/images/mvert/82.png',      
  mvert_83 : publicurl + '/shared/images/mvert/83.png',      
  mvert_84 : publicurl + '/shared/images/mvert/84.png',      
  mvert_85 : publicurl + '/shared/images/mvert/85.png',      
  threed_1: publicurl + '/shared/images/3d/1.png',
  threed_2: publicurl + '/shared/images/3d/2.png',
  threed_3: publicurl + '/shared/images/3d/3.png',
  threed_4: publicurl + '/shared/images/3d/4.png',
  threed_5: publicurl + '/shared/images/3d/5.png',
  threed_6: publicurl + '/shared/images/3d/6.png',
  threed_7: publicurl + '/shared/images/3d/7.png',
  threed_8: publicurl + '/shared/images/3d/8.png',
  threed_9: publicurl + '/shared/images/3d/9.png',
  threed_10: publicurl + '/shared/images/3d/10.png',
  threed_11: publicurl + '/shared/images/3d/11.png',
  threed_12: publicurl + '/shared/images/3d/12.png',
  threed_13: publicurl + '/shared/images/3d/13.png',
  threed_14: publicurl + '/shared/images/3d/14.png',
  threed_15: publicurl + '/shared/images/3d/15.png',
  threed_16: publicurl + '/shared/images/3d/16.png',
  threed_17: publicurl + '/shared/images/3d/17.png',
  threed_18: publicurl + '/shared/images/3d/18.png',
  threed_19: publicurl + '/shared/images/3d/19.png',
  threed_20: publicurl + '/shared/images/3d/20.png',
  threed_21: publicurl + '/shared/images/3d/21.png',
  threed_22: publicurl + '/shared/images/3d/22.png',
  threed_23: publicurl + '/shared/images/3d/23.png',
  threed_24: publicurl + '/shared/images/3d/24.png',
  threed_25: publicurl + '/shared/images/3d/25.png',
  threed_26: publicurl + '/shared/images/3d/26.png',
  threed_27: publicurl + '/shared/images/3d/27.png',
  threed_28: publicurl + '/shared/images/3d/28.png',
  threed_29: publicurl + '/shared/images/3d/29.png',
  threed_30: publicurl + '/shared/images/3d/30.png',
  threed_31: publicurl + '/shared/images/3d/31.png',
  threed_32: publicurl + '/shared/images/3d/32.png',
  threed_33: publicurl + '/shared/images/3d/33.png',
  threed_34: publicurl + '/shared/images/3d/34.png',
  threed_35: publicurl + '/shared/images/3d/35.png',
  threed_36: publicurl + '/shared/images/3d/36.png',
  threed_37: publicurl + '/shared/images/3d/37.png',
  threed_38: publicurl + '/shared/images/3d/38.png',
  threed_39: publicurl + '/shared/images/3d/39.png',
  threed_40: publicurl + '/shared/images/3d/40.png',
  threed_41: publicurl + '/shared/images/3d/41.png',
  threed_42: publicurl + '/shared/images/3d/42.png', 
  works_1: publicurl + '/shared/images/works/1.png',
  works_2: publicurl + '/shared/images/works/2.png',
  works_3: publicurl + '/shared/images/works/3.png',
  works_4: publicurl + '/shared/images/works/4.png',
  works_5: publicurl + '/shared/images/works/5.png',
  works_6: publicurl + '/shared/images/works/6.png',
  works_7: publicurl + '/shared/images/works/7.png',
  works_8: publicurl + '/shared/images/works/8.png',
  works_9: publicurl + '/shared/images/works/9.png',
  works_10: publicurl + '/shared/images/works/10.png',
  works_11: publicurl + '/shared/images/works/11.png',
  works_12: publicurl + '/shared/images/works/12.png',
  works_13: publicurl + '/shared/images/works/13.png',
  works_14: publicurl + '/shared/images/works/14.png',
  works_15: publicurl + '/shared/images/works/15.png',
  works_16: publicurl + '/shared/images/works/16.png',
  works_17: publicurl + '/shared/images/works/17.png',
  works_18: publicurl + '/shared/images/works/18.png',
  works_19: publicurl + '/shared/images/works/19.png',
  works_20: publicurl + '/shared/images/works/20.png',
  works_21: publicurl + '/shared/images/works/21.png',
  works_22: publicurl + '/shared/images/works/22.png',
  works_23: publicurl + '/shared/images/works/23.png',
  works_24: publicurl + '/shared/images/works/24.png',
  works_25: publicurl + '/shared/images/works/25.png',
  works_26: publicurl + '/shared/images/works/26.png',
  works_27: publicurl + '/shared/images/works/27.png',
  works_28: publicurl + '/shared/images/works/28.png',
  works_29: publicurl + '/shared/images/works/29.png',
  works_30: publicurl + '/shared/images/works/30.png',
  works_31: publicurl + '/shared/images/works/31.png',
  works_32: publicurl + '/shared/images/works/32.png',
  works_33: publicurl + '/shared/images/works/33.png',
  works_34: publicurl + '/shared/images/works/34.png',
  works_35: publicurl + '/shared/images/works/35.png',
  works_36: publicurl + '/shared/images/works/36.png',
  works_37: publicurl + '/shared/images/works/37.png', 
  acssesuar_balls_1: publicurl + '/shared/images/acssesuar/balls/1.png', 
  acssesuar_balls_2: publicurl + '/shared/images/acssesuar/balls/2.png', 
  acssesuar_balls_3: publicurl + '/shared/images/acssesuar/balls/3.png', 
  acssesuar_balls_4: publicurl + '/shared/images/acssesuar/balls/4.png', 
  acssesuar_balls_5: publicurl + '/shared/images/acssesuar/balls/5.png', 
  acssesuar_balls_6: publicurl + '/shared/images/acssesuar/balls/6.png', 
  acssesuar_fences_1: publicurl + '/shared/images/acssesuar/fences/1.png', 
  acssesuar_fences_2: publicurl + '/shared/images/acssesuar/fences/2.png', 
  acssesuar_fences_3: publicurl + '/shared/images/acssesuar/fences/3.png', 
  acssesuar_fences_4: publicurl + '/shared/images/acssesuar/fences/4.png', 
  acssesuar_fences_5: publicurl + '/shared/images/acssesuar/fences/5.png', 
  acssesuar_granitetile_1: publicurl + '/shared/images/acssesuar/granitetile/1.png', 
  acssesuar_granitetile_2: publicurl + '/shared/images/acssesuar/granitetile/2.png', 
  acssesuar_granitetile_3: publicurl + '/shared/images/acssesuar/granitetile/3.png', 
  acssesuar_granitetile_4: publicurl + '/shared/images/acssesuar/granitetile/4.png', 
  acssesuar_granitetile_5: publicurl + '/shared/images/acssesuar/granitetile/5.png', 
  acssesuar_granitetile_6: publicurl + '/shared/images/acssesuar/granitetile/6.png', 
  acssesuar_granitetile_7: publicurl + '/shared/images/acssesuar/granitetile/7.png', 
  acssesuar_lamps_1: publicurl + '/shared/images/acssesuar/lamps/1.png', 
  acssesuar_lamps_2: publicurl + '/shared/images/acssesuar/lamps/2.png', 
  acssesuar_lamps_3: publicurl + '/shared/images/acssesuar/lamps/3.png', 
  acssesuar_lamps_4: publicurl + '/shared/images/acssesuar/lamps/4.png', 
  acssesuar_lamps_5: publicurl + '/shared/images/acssesuar/lamps/5.png', 
  acssesuar_lamps_6: publicurl + '/shared/images/acssesuar/lamps/6.png', 
  acssesuar_lamps_7: publicurl + '/shared/images/acssesuar/lamps/7.png', 
  acssesuar_lamps_8: publicurl + '/shared/images/acssesuar/lamps/8.png', 
  acssesuar_other_1: publicurl + '/shared/images/acssesuar/other/1.png', 
  acssesuar_other_2: publicurl + '/shared/images/acssesuar/other/2.png', 
  acssesuar_other_3: publicurl + '/shared/images/acssesuar/other/3.png', 
  acssesuar_other_4: publicurl + '/shared/images/acssesuar/other/4.png', 
  acssesuar_other_5: publicurl + '/shared/images/acssesuar/other/5.png', 
  acssesuar_other_6: publicurl + '/shared/images/acssesuar/other/6.png', 
  acssesuar_other_7: publicurl + '/shared/images/acssesuar/other/7.png', 
  acssesuar_pavingtile_1: publicurl + '/shared/images/acssesuar/pavingtile/1.png', 
  acssesuar_pavingtile_2: publicurl + '/shared/images/acssesuar/pavingtile/2.png', 
  acssesuar_pavingtile_3: publicurl + '/shared/images/acssesuar/pavingtile/3.png', 
  acssesuar_pavingtile_4: publicurl + '/shared/images/acssesuar/pavingtile/4.png', 
  acssesuar_pavingtile_5: publicurl + '/shared/images/acssesuar/pavingtile/5.png', 
  acssesuar_pavingtile_6: publicurl + '/shared/images/acssesuar/pavingtile/6.png', 
  acssesuar_porcelaintile_1: publicurl + '/shared/images/acssesuar/porcelaintile/1.png', 
  acssesuar_porcelaintile_2: publicurl + '/shared/images/acssesuar/porcelaintile/2.png', 
  acssesuar_vases_1: publicurl + '/shared/images/acssesuar/vases/1.png', 
  acssesuar_vases_2: publicurl + '/shared/images/acssesuar/vases/2.png', 
  acssesuar_vases_3: publicurl + '/shared/images/acssesuar/vases/3.png', 
  acssesuar_vases_4: publicurl + '/shared/images/acssesuar/vases/4.png', 
  acssesuar_vases_5: publicurl + '/shared/images/acssesuar/vases/5.png', 
  acssesuar_vases_6: publicurl + '/shared/images/acssesuar/vases/6.png', 
  acssesuar_vases_7: publicurl + '/shared/images/acssesuar/vases/7.png', 
  acssesuar_vases_8: publicurl + '/shared/images/acssesuar/vases/8.png', 
  acssesuar_vases_9: publicurl + '/shared/images/acssesuar/vases/9.png', 
  acssesuar_vases_10: publicurl + '/shared/images/acssesuar/vases/10.png', 
  acssesuar_vases_11: publicurl + '/shared/images/acssesuar/vases/11.png', 
  acssesuar_vases_12: publicurl + '/shared/images/acssesuar/vases/12.png', 
  acssesuar_vases_13: publicurl + '/shared/images/acssesuar/vases/13.png', 
  acssesuar_vases_14: publicurl + '/shared/images/acssesuar/vases/14.png', 
  acssesuar_vases_15: publicurl + '/shared/images/acssesuar/vases/15.png', 
  acssesuar_vases_16: publicurl + '/shared/images/acssesuar/vases/16.png', 
  acssesuar_vases_17: publicurl + '/shared/images/acssesuar/vases/17.png', 
  acssesuar_vases_18: publicurl + '/shared/images/acssesuar/vases/18.png', 
  acssesuar_vases_19: publicurl + '/shared/images/acssesuar/vases/19.png', 
  acssesuar_vases_20: publicurl + '/shared/images/acssesuar/vases/20.png', 
  acssesuar_vases_21: publicurl + '/shared/images/acssesuar/vases/21.png', 
  acssesuar_vases_22: publicurl + '/shared/images/acssesuar/vases/22.png', 
  acssesuar_vases_23: publicurl + '/shared/images/acssesuar/vases/23.png', 
}