import images from '../assets/images';
import images_shared from '../shared-images';
 
const telephone = '+79184455468';

const telephoneart = '+7-918-445-54-68';

const API_URL = process.env.NODE_ENV == 'development' ? 'http://localhost:4000/api' : 'https://monumentcompany.ru/api'; 
 
const { 
  chpu_1,
  chpu_2,
  chpu_3,
  chpu_4,
  chpu_5,
  chpu_6,
  chpu_7,
  chpu_8,
  chpu_9,
  chpu_10,
  chpu_11,
  chpu_12,
  chpu_13,
  chpu_14,
  chpu_15,
  chpu_16,
  chpu_17,
  chpu_18,
  chpu_19,
  chpu_20,
  chpu_21,
  chpu_22,
  chpu_23,
  chpu_24,
  chpu_25,
  chpu_26,
  chpu_27,
  chpu_28,
  chpu_29,
  chpu_30,
  chpu_31,
  chpu_32,
  chpu_33,
  chpu_34,
  chpu_35,
  chpu_36,
  chpu_37,
  chpu_38,
  chpu_39,
  chpu_40,
  chpu_41,
  chpu_42,
  chpu_43,
  chpu_44,
  chpu_45,
  chpu_46,
  chpu_47,
  chpu_48,
  chpu_49,
  chpu_50,
  chpu_51,
  chpu_52,
  chpu_53,
  chpu_54,
  chpu_55,
  chpu_56,
  chpu_57,
  chpu_58,
  chpu_59,
  chpu_60,
  chpu_61,
  chpu_62,
  chpu_63,
  chpu_64,
  chpu_65,
  chpu_66,
  chpu_67,
  chpu_68,
  chpu_69,
  chpu_70,
  chpu_71,
  chpu_72,
  chpu_73,
  chpu_74,
  chpu_75,
  chpu_76,
  chpu_77,
  chpu_78,
  chpu_79,
  chpu_80,
  chpu_81,
  chpu_82,
  chpu_83,
  chpu_84,
  chpu_85,
  chpu_86,
  chpu_87,
  chpu_88,
  chpu_89,
  chpu_90,
  chpu_91,
  chpu_92,
  chpu_93,
  chpu_94,
  chpu_95,
  chpu_96,
  chpu_97,
  chpu_98,
  chpu_99,
  chpu_100,
  chpu_101,
  chpu_102,
  chpu_103,
  chpu_104,
  chpu_105,
  chpu_106,
  chpu_107,
  chpu_108,
  chpu_109,
  chpu_110,
  chpu_111,
  chpu_112,
  chpu_113,
  chpu_114,
  chpu_115,
  chpu_116,
  chpu_117,
  chpu_118,
  chpu_119,
  chpu_120,
  chpu_121,
  chpu_122,
  chpu_123,
  chpu_124,
  chpu_125,
  chpu_126,
  chpu_127,
  chpu_128,
  chpu_129,
  chpu_130,
  chpu_131,
  chpu_132,
  chpu_133,
  chpu_134,
  chpu_135,
  chpu_136,
  chpu_137,
  chpu_138,
  chpu_139,
  chpu_140,
  chpu_141,
  chpu_142,
  chpu_143,
  chpu_144,
  chpu_145,
  chpu_146,
  chpu_147,
  chpu_148,
  chpu_149,
  chpu_150,
  chpu_151,
  chpu_152,
  chpu_153,
  chpu_154,
  chpu_155,
  chpu_156,
  chpu_157,
  chpu_158,
  chpu_159,
  chpu_160,
  chpu_161,
  chpu_162,
  chpu_163,
  chpu_164,
  chpu_165,
  chpu_166,
  chpu_167,
  chpu_168,
  chpu_169,
  chpu_170,
  chpu_171,
  chpu_172,
  chpu_173,
  chpu_174,
  chpu_175,
  chpu_176,
  chpu_177,
  chpu_178,
  chpu_179,
  chpu_180,
  chpu_181,
  chpu_182,
  chpu_183,
  chpu_184,
  chpu_185,
  chpu_186,
  chpu_187,
  chpu_188,
  chpu_189,
  chpu_190,
  chpu_191,
  chpu_192,
  chpu_193,
  chpu_194,
  chpu_195,
  chpu_196,
  chpu_197,
  chpu_198,
  chpu_199,
  chpu_200,
  chpu_201,
  chpu_202,
  chpu_203,
  chpu_204,
  chpu_205,
  chpu_206,
  chpu_207,
  chpu_208,
  chpu_209,
  chpu_210,
  chpu_211,
  chpu_212,
  chpu_213,
  chpu_214,
  chpu_215,
  chpu_216,
  chpu_217,
  chpu_218,
  chpu_219,
  chpu_220,
  chpu_221,
  chpu_222,
  chpu_223,
  chpu_224,
  chpu_225,
  chpu_226,
  chpu_227,
  chpu_228,
  chpu_229,
  chpu_230,
  chpu_231,
  chpu_232,
  chpu_233,
  chpu_234,
  chpu_235,
  chpu_236,
  chpu_237,
  chpu_238,
  chpu_239,
  chpu_240,
  chpu_241,
  chpu_242,
  chpu_243,
  chpu_244,
  chpu_245,
  chpu_246,
  chpu_247,
  chpu_248,
  chpu_249,
  chpu_250,
  chpu_251,
  chpu_252,
  chpu_253,
  chpu_254,
  chpu_255,
  chpu_256,
  chpu_257,
  chpu_258,
  chpu_259,
  chpu_260,
  chpu_261,
  chpu_262,
  chpu_263,
  chpu_264,
  chpu_265,
  chpu_266,
  chpu_267,
  chpu_268,
  chpu_269,
  chpu_270,
  chpu_271,
  chpu_272,
  chpu_273,
  chpu_274,
  chpu_275,
  chpu_276,
  chpu_277,
  chpu_278,
  chpu_279,
  chpu_280,
  chpu_281,
  chpu_282,
  chpu_283,
  chpu_284,
  chpu_285,
  chpu_286,
  chpu_287,
  chpu_288,
  chpu_289,
  chpu_290,
  chpu_291,
  chpu_292,
  chpu_293,
  chpu_294,
  chpu_295,
  chpu_296,
  chpu_297,
  chpu_298,
  chpu_299,
  chpu_300,
  chpu_301,
  chpu_302,
  chpu_303,
  chpu_304,
  chpu_305,
  chpu_306,
  chpu_307,
  chpu_308,
  chpu_309,
  chpu_310,
  chpu_311,
  chpu_312,
  chpu_313,
  chpu_314,
  chpu_315,
  chpu_316,
  chpu_317,
  chpu_318,
  chpu_319,
  chpu_320,
  chpu_321,
  chpu_322,
  kids_1,
  kids_2,
  kids_3,
  kids_4,
  kids_5,
  kids_6,
  kids_7,
  kids_8,
  kids_9,
  kids_10,
  kids_11,
  kids_12,
  kids_13,
  kids_14,
  kids_15,
  kids_16,
  kids_17,
  kids_18,
  kids_19,
  kids_20,
  kids_21,
  kids_22,
  kids_23,
  kids_24,
  kids_25,
  kids_26,
  kids_27,
  kids_28,
  kids_29,
  kids_30,
  kids_31,
  mvert_1,
  mvert_2,
  mvert_3,
  mvert_4,
  mvert_5,
  mvert_6,
  mvert_7,
  mvert_8,
  mvert_9,
  mvert_10,
  mvert_11,
  mvert_12,
  mvert_13,
  mvert_14,
  mvert_15,
  mvert_16,
  mvert_17,
  mvert_18,
  mvert_19,
  mvert_20,
  mvert_21,
  mvert_22,
  mvert_23,
  mvert_24,
  mvert_25,
  mvert_26,
  mvert_27,
  mvert_28,
  mvert_29,
  mvert_30,
  mvert_31,
  mvert_32,
  mvert_33,
  mvert_34,
  mvert_35,
  mvert_36,
  mvert_37,
  mvert_38,
  mvert_39,
  mvert_40,
  mvert_41,
  mvert_42,
  mvert_43,
  mvert_44,
  mvert_45,
  mvert_46,
  mvert_47,
  mvert_48,
  mvert_49,
  mvert_50,
  mvert_51,
  mvert_52,
  mvert_53,
  mvert_54,
  mvert_55,
  mvert_56,
  mvert_57,
  mvert_58,
  mvert_59,
  mvert_60,
  mvert_61,
  mvert_62,
  mvert_63,
  mvert_64,
  mvert_65,
  mvert_66,
  mvert_67,
  mvert_68,
  mvert_69,
  mvert_70,
  mvert_71,
  mvert_72,
  mvert_73,
  mvert_74,
  mvert_75,
  mvert_76,
  mvert_77,
  mvert_78,
  mvert_79,
  mvert_80,
  mvert_81,
  mvert_82,
  mvert_83,
  mvert_84,
  mvert_85,
  mgoriz_1,
  mgoriz_2,
  mgoriz_3,
  mgoriz_4,
  mgoriz_5,
  mgoriz_6,
  mgoriz_7,
  mgoriz_8,
  mgoriz_9,
  mgoriz_10,
  mgoriz_11,
  mgoriz_12,
  mgoriz_13,
  mgoriz_14,
  mgoriz_15,
  mgoriz_16,
  mgoriz_17,
  mgoriz_18,
  mgoriz_19,
  mgoriz_20,
  mgoriz_21,
  mgoriz_22,
  mgoriz_23,
  mgoriz_24,
  ggoriz_1,
  ggoriz_2,
  ggoriz_3,
  ggoriz_4,
  ggoriz_5,
  ggoriz_6,
  ggoriz_7,
  ggoriz_8,
  ggoriz_9,
  ggoriz_10,
  ggoriz_11,
  ggoriz_12,
  ggoriz_13,
  ggoriz_14,
  ggoriz_15,
  ggoriz_16,
  ggoriz_17,
  ggoriz_18,
  ggoriz_19,
  ggoriz_20,
  ggoriz_21,
  ggoriz_22,
  ggoriz_23,
  ggoriz_24,
  ggoriz_25,
  ggoriz_26,
  ggoriz_27,
  ggoriz_28,
  ggoriz_29,
  ggoriz_30,
  ggoriz_31,
  ggoriz_32,
  ggoriz_33,
  ggoriz_34,
  ggoriz_35,
  ggoriz_36,
  ggoriz_37,
  ggoriz_38,
  ggoriz_39,
  ggoriz_40,
  ggoriz_41,
  ggoriz_42,
  ggoriz_43,
  ggoriz_44,
  ggoriz_45,
  ggoriz_46,
  ggoriz_47,
  ggoriz_48,
  ggoriz_49,
  ggoriz_50,
  ggoriz_51,
  ggoriz_52,
  ggoriz_53,
  ggoriz_54,
  ggoriz_55,
  ggoriz_56,
  ggoriz_57,
  ggoriz_58,
  ggoriz_59,
  ggoriz_60,
  ggoriz_61,
  ggoriz_62,
  ggoriz_63,
  ggoriz_64,
  ggoriz_65,
  ggoriz_66,
  ggoriz_67,
  ggoriz_68,
  ggoriz_69,
  ggoriz_70,
  ggoriz_71,
  ggoriz_72,
  ggoriz_73,
  ggoriz_74,
  ggoriz_75,
  ggoriz_76,
  ggoriz_77,
  ggoriz_78,
  ggoriz_79,
  ggoriz_80,
  ggoriz_81,
  gvert_1,
  gvert_2,
  gvert_3,
  gvert_4,
  gvert_5,
  gvert_6,
  gvert_7,
  gvert_8,
  gvert_9,
  gvert_10,
  gvert_11,
  gvert_12,
  gvert_13,
  gvert_14,
  gvert_15,
  gvert_16,
  gvert_17,
  gvert_18,
  gvert_19,
  gvert_20,
  gvert_21,
  gvert_22,
  gvert_23,
  gvert_24,
  gvert_25,
  gvert_26,
  gvert_27,
  gvert_28,
  gvert_29,
  gvert_30,
  gvert_31,
  gvert_32,
  gvert_33,
  gvert_34,
  gvert_35,
  gvert_36,
  gvert_37,
  gvert_38,
  gvert_39,
  gvert_40,
  gvert_41,
  gvert_42,
  gvert_43,
  gvert_44,
  gvert_45,
  gvert_46,
  gvert_47,
  gvert_48,
  gvert_49,
  gvert_50,
  gvert_51,
  gvert_52,
  gvert_53,
  gvert_54a,
  gvert_54b,
  gvert_54c,
  gvert_54d,
  gvert_54e,
  gvert_55,
  gvert_56,
  gvert_57,
  gvert_58,
  gvert_59,
  gvert_60,
  gvert_61,
  gvert_62,
  gvert_63,
  gvert_64,
  gvert_65,
  gvert_66,
  gvert_67,
  gvert_68,
  gvert_69,
  gvert_70,
  gvert_71,
  gvert_72,
  gvert_73,
  gvert_74,
  gvert_75,
  gvert_76,
  gvert_77,
  gvert_78,
  gvert_79,
  gvert_80,
  gvert_81,
  gvert_82,
  gvert_83,
  gvert_84,
  gvert_85,
  gvert_86,
  gvert_87,
  gvert_88,
  gvert_89,
  gvert_90,
  gvert_91,
  gvert_92,
  gvert_93,
  gvert_94,
  gvert_95,
  gvert_96,
  gvert_97,
  gvert_98,
  gvert_99,
  gvert_100,
  gvert_101,
  gvert_102,
  gvert_103,
  gvert_104,
  gvert_105,
  gvert_106,
  gvert_107,
  gvert_108,
  gvert_109,
  gvert_110,
  gvert_111,
  gvert_112,
  gvert_113,
  gvert_114,
  gvert_115,
  gvert_116,
  gvert_117,
  gvert_118,
  gvert_119,
  gvert_120,
  gvert_121,
  gvert_122,
  gvert_123,
  gvert_124,
  gvert_125,
  gvert_126,
  gvert_127,
  gvert_128,
  gvert_129,
  gvert_130,
  gvert_131,
  gvert_132,
  gvert_133,
  gvert_134,
  gvert_135,
  gvert_136,
  gvert_137,
  gvert_138,
  gvert_139,
  gvert_140,
  gvert_141,
  gvert_142,
  gvert_143,
  gvert_144,
  gvert_145,
  gvert_146,
  gvert_147,
  gvert_148,
  gvert_149,
  gvert_150,
  gvert_151,
  gvert_152,
  gvert_153,
  gvert_154,
  gvert_155,
  gvert_156,
  gvert_157,
  gvert_158,
  gvert_159,
  threed_1,
  threed_2,
  threed_3,
  threed_4,
  threed_5,
  threed_6,
  threed_7,
  threed_8,
  threed_9,
  threed_10,
  threed_11,
  threed_12,
  threed_13,
  threed_14,
  threed_15,
  threed_16,
  threed_17,
  threed_18,
  threed_19,
  threed_20,
  threed_21,
  threed_22,
  threed_23,
  threed_24,
  threed_25,
  threed_26,
  threed_27,
  threed_28,
  threed_29,
  threed_30,
  threed_31,
  threed_32,
  threed_33,
  threed_34,
  threed_35,
  threed_36,
  threed_37,
  threed_38,
  threed_39,
  threed_40,
  threed_41,
  threed_42, 
  works_1,
  works_2,
  works_3,
  works_4,
  works_5,
  works_6,
  works_7,
  works_8,
  works_9,
  works_10,
  works_11,
  works_12,
  works_13,
  works_14,
  works_15,
  works_16,
  works_17,
  works_18,
  works_19,
  works_20,
  works_21,
  works_22,
  works_23,
  works_24,
  works_25,
  works_26,
  works_27,
  works_28,
  works_29,
  works_30,
  works_31,
  works_32,
  works_33,
  works_34,
  works_35,
  works_36,
  works_37, 
  acssesuar_balls_1, 
  acssesuar_balls_2, 
  acssesuar_balls_3, 
  acssesuar_balls_4, 
  acssesuar_balls_5, 
  acssesuar_balls_6, 
  acssesuar_fences_1, 
  acssesuar_fences_2, 
  acssesuar_fences_3, 
  acssesuar_fences_4, 
  acssesuar_fences_5, 
  acssesuar_granitetile_1, 
  acssesuar_granitetile_2, 
  acssesuar_granitetile_3, 
  acssesuar_granitetile_4, 
  acssesuar_granitetile_5, 
  acssesuar_granitetile_6, 
  acssesuar_granitetile_7, 
  acssesuar_lamps_1, 
  acssesuar_lamps_2, 
  acssesuar_lamps_3, 
  acssesuar_lamps_4, 
  acssesuar_lamps_5, 
  acssesuar_lamps_6, 
  acssesuar_lamps_7, 
  acssesuar_lamps_8, 
  acssesuar_other_1, 
  acssesuar_other_2, 
  acssesuar_other_3, 
  acssesuar_other_4, 
  acssesuar_other_5, 
  acssesuar_other_6, 
  acssesuar_other_7, 
  acssesuar_pavingtile_1, 
  acssesuar_pavingtile_2, 
  acssesuar_pavingtile_3, 
  acssesuar_pavingtile_4, 
  acssesuar_pavingtile_5, 
  acssesuar_pavingtile_6, 
  acssesuar_porcelaintile_1, 
  acssesuar_porcelaintile_2, 
  acssesuar_vases_1, 
  acssesuar_vases_2, 
  acssesuar_vases_3, 
  acssesuar_vases_4, 
  acssesuar_vases_5, 
  acssesuar_vases_6, 
  acssesuar_vases_7, 
  acssesuar_vases_8, 
  acssesuar_vases_9, 
  acssesuar_vases_10, 
  acssesuar_vases_11, 
  acssesuar_vases_12, 
  acssesuar_vases_13, 
  acssesuar_vases_14, 
  acssesuar_vases_15, 
  acssesuar_vases_16, 
  acssesuar_vases_17, 
  acssesuar_vases_18, 
  acssesuar_vases_19, 
  acssesuar_vases_20, 
  acssesuar_vases_21, 
  acssesuar_vases_22, 
  acssesuar_vases_23, 
} = images_shared;
 
const products = [{
    category: 'granit', // Гранит
    text: 'Гранит',
    monuments: [{
        subcategory: 'chpu', // Гранит Чпу
        subtext: 'Чпу',
        items: [ // В этот массив вставляется вся информация по памятнику
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_1, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '1', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_2, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '2', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_3, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '3', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_4, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '4', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_5, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '5', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_6, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['62 500', '77 300', '102 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '6', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_7, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '7', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_8, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '8', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_9, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '9', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_10, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '10', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_11, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '11', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_12, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 500', '72 300', '97 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '12', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_13, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '13', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_14, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '14', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_15, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '15', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_16, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '16', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_17, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '17', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_18, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '18', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_19, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['63 200', '78 000', '103 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '19', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_20, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '20', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_21, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['62 800', '77 600', '102 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '21', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_22, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '22', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_23, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['56 800', '71 600', '96 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '23', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_24, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '24', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_25, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['65 500', '80 300', '105 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '25', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_26, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '26', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_27, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '27', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_28, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '28', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_29, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '29', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_30, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['63 200', '78 000', '103 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '30', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_31, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '31', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_32, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '32', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_33, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '33', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_34, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['54 800', '69 600', '94 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '34', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_35, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '35', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_36, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '36', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_37, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '37', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_38, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '38', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_39, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '39', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_40, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '40', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_41, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '41', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_42, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '42', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_43, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '43', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_44, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '44', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_45, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '45', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_46, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '46', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_47, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '47', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_48, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '48', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_49, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '49', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_50, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '50', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_51, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '51', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_52, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '52', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_53, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '53', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_54, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '54', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_55, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '55', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_56, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 500', '72 300', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '56', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_57, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['63 300', '78 100', '103 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '57', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_58, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['63 300', '78 100', '103 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '58', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_59, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 300', '75 100', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '59', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_60, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 300', '80 100', '105 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '60', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_61, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '75 100', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '61', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_62, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '75 100', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '62', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_63, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '75 100', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '63', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_64, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '75 100', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '64', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_65, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 300', '72 100', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '65', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_66, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 600', '72 100', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '66', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_67, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '75 100', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '67', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_68, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '75 100', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '68', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_69, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '69', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_70, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '70', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_71, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '71', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_72, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '72', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_73, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '73', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_74, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '74', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_75, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '75', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_76, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['55 800', '70 600', '95 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '76', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_77, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['55 800', '70 600', '95 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '77', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_78, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '78', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_79, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '79', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_80, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '80', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_81, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 800', '72 600', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '81', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_82, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 800', '72 600', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '82', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_83, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 800', '72 600', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '83', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_84, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 800', '72 600', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '84', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_85, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 800', '72 600', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '85', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_86, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 800', '72 600', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '86', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_87, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 800', '72 600', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '87', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_88, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['57 800', '72 600', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '88', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_89, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '89', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_90, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['56 500', '71 300', '96 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '90', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_91, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['55 800', '70 600', '95 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '91', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_92, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '92', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_93, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['55 800', '70 600', '95 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '93', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_94, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '94', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_95, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '95', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_96, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '96', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_97, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['55 800', '70 600', '95 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '97', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_98, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '98', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_99, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '99', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_100, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '100', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_101, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '101', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_102, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['55 800', '70 600', '95 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '102', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_103, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '103', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_104, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '70 600', '95 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '104', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_105, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '105', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_106, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '106', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_107, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 800', '75 600', '100 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '107', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_108, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 500', '75 300', '100 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '108', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_109, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '109', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_110, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 800', '75 800', '100 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '110', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_111, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '111', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_112, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '112', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_113, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '113', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_114, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '114', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_115, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '115', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_116, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '116', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_117, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '117', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_118, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '118', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_119, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '119', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_120, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '120', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_121, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '121', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_122, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '122', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_123, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '123', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_124, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '124', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_125, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '125', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_126, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '126', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_127, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '127', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_128, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '128', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_129, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '129', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_130, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '130', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_131, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '131', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_132, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['ДОГОВОР', 'ДОГОВОР', 'ДОГОВОР'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '132', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_133, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '133', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_134, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '134', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_135, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '135', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_136, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '136', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_137, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['58 300', '73 100', '98 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '137', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_138, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '138', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_139, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '139', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_140, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '140', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_141, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '141', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_142, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '142', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_143, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '143', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_144, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['ДОГОВОР', 'ДОГОВОР', 'ДОГОВОР'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '144', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_145, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '145', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_146, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '146', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_147, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '147', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_148, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '148', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_149, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '149', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_150, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '150', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_151, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '151', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_152, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '152', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_153, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '153', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_154, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '154', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_155, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '155', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_156, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '156', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_157, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '157', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_158, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '158', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_159, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 800', '75 800', '100 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '159', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_160, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '160', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_161, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '161', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_162, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '162', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_163, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '163', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_164, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '164', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_165, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '165', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_166, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '166', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_167, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '167', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_168, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '168', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_169, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '169', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_170, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '170', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_171, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '171', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_172, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '172', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_173, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '173', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_174, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '174', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_175, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '175', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_176, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '176', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_177, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '177', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_178, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '178', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_179, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '179', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_180, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '180', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_181, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '181', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_182, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '182', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_183, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '183', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_184, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '184', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_185, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['54 800', '69 600', '94 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '185', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_186, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '186', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_187, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '187', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_188, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '188', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_189, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '189', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_190, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '190', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_191, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '191', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_192, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '192', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_193, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '193', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_194, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '194', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_195, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '195', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_196, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '196', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_197, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '197', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_198, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '198', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_199, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '199', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_200, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '200', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_201, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '201', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_202, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '202', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_203, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '203', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_204, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '204', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_205, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '205', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_206, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '206', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_207, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '207', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_208, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '208', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_209, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '209', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_210, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '210', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_211, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '211', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_212, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '212', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_213, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '213', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_214, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '214', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_215, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '215', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_216, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '216', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_217, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '217', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_218, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '218', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_219, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '219', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_220, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '220', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_221, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['62 800', '77 600', '102 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '221', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_222, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '222', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_223, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['62 800', '77 600', '102 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '223', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_224, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['62 800', '77 600', '102 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '224', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_225, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '225', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_226, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '226', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_227, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '227', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_228, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '228', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_229, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '229', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_230, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '230', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_231, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '231', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_232, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '232', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_233, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '233', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_234, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '234', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_235, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '235', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_236, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '236', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_237, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '237', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_238, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '238', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_239, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '239', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_240, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '240', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_241, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '241', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_242, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '242', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_243, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '243', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_244, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '244', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_245, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '245', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_246, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '246', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_247, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '247', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_248, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '248', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_249, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '249', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_250, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '250', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_251, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '251', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_252, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '252', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_253, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '253', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_254, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '254', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_255, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '255', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_256, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '256', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_257, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '257', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_258, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '258', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_259, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '259', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_260, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '260', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_261, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '261', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_262, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '262', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_263, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '263', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_264, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '264', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_265, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '265', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_266, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '266', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_267, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['54 800', '69 600', '94 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '267', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_268, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '268', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_269, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '269', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_270, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '270', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_271, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '271', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_272, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '272', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_273, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '273', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_274, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '274', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_275, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '275', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_276, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '276', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_277, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '277', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_278, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '278', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_279, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '279', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_280, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '280', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_281, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '281', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_282, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '282', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_283, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '283', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_284, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '284', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_285, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '285', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_286, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '286', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_287, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '287', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_288, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '288', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_289, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '289', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_290, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '290', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_291, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '291', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_292, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '292', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_293, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '293', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_294, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '294', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_295, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '295', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_296, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '296', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_297, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '297', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_298, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '298', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_299, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '299', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_300, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['69 800', '84 600', '109 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '300', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_301, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '301', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_302, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '302', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_303, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '303', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_304, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '304', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_305, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '305', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_306, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '306', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_307, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '307', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_308, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '308', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_309, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '309', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_310, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '310', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_311, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '311', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_312, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '312', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_313, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '313', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_314, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['64 800', '79 600', '104 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '314', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_315, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '315', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_316, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '316', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_317, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '317', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_318, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['59 800', '74 600', '99 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '318', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_319, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['67 300', '82 100', '107 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '319', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_320, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '320', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_321, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '321', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
          {
            material: 'granit', // материал
            count: 1, // не трогать
            img: chpu_322, // фото это не трогать
            title: 'Памятник резной', // Название
            description: 'Памятник резной из гранита', // Описание памятника любое
            price: ['60 300', '60 600', '100 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'chpu',
            id: '322', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
              <div>
                <h1>Дополнительную информацию можно уточнить по телефону:</h1>  
                <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
              </div>        
            ` // это инфа любая в данном случае телефон
          },
        ]
      },
      {
        subcategory: 'vgranit', // Гранит вертикальный
        subtext: 'Вертикальный',
        items: [ // В этот массив вставляется вся информация по памятнику
          {
            material: 'granit',
            count: 1,
            img: gvert_1,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '1', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_2,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '2', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_3,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 900', '63 900', '88 100'], // цена TUT
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '3', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_4,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '4', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_5,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '5', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_6,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '6', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_7,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '7', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_8,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '8', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_9,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '9', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_10,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '10', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_11,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '11', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_12,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '12', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_13,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '13', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_14,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '14', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_15,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '15', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_16,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '16', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_17,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['46 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '17', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_18,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '18', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_19,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '19', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_20,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '20', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_21,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '21', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_22,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '22', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_23,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '23', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_24,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '24', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_25,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '25', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_26,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '26', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_27,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '27', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_28,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '28', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_29,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 000', '66 300', '90 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '29', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_30,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 000', '66 300', '90 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '30', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_31,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '31', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_32,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '32', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_33,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '33', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_34,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '34', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_35,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '35', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_36,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 000', '66 300', '90 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '36', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_37,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 000', '66 300', '90 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '37', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_38,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '38', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_39,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['65 600', '86 200', '112 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '39', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_40,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '40', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_41,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '41', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_42,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '42', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_43,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '43', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_44,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '44', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_45,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '45', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_46,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '46', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_47,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '47', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_48,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '48', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_49,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '49', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_50,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '50', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_51,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '51', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_52,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '52', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_53,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '53', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_54a,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '54a', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_54b,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '54b', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_54c,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '54c', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_54d,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '54d', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_54e,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '54e', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_55,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 000', '66 300', '90 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '55', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_56,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['62 800', '81 700', '107 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '56', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_57,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '57', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_58,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 600', '62 600', '86 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '58', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_59,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '59', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_60,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '60', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_61,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '61', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_62,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['64 000', '83 100', '92 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '62', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_63,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '63', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_64,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['64 000', '83 100', '92 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '64', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_65,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '65', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_66,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['59 300', '75 900', '101 600'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '66', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_67,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 600', '77 200', '103 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '67', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_68,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '68', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_69,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['58 000', '72 300', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '69', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_70,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '70', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_71,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '71', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_72,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['58 000', '72 300', '97 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '72', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_73,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['54 600', '66 300', '91 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '73', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_74,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['62 800', '77 100', '101 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '74', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_75,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '75', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_76,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['65 000', '84 300', '110 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '76', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_77,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['65 000', '84 300', '110 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '77', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_78,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['68 900', '87 900', '114 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '78', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_79,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '79', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_80,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['65 000', '84 300', '110 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '80', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_81,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['66 200', '85 600', '111 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '81', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_82,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['65 000', '84 300', '110 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '82', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_83,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['54 600', '66 300', '91 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '83', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_84,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['49 700', '63 900', '87 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '84', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_85,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '85', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_86,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '86', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_87,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '87', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_88,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '88', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_89,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 500', '64 900', '90 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '89', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_90,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '90', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_91,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '91', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_92,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['51 900', '63 400', '88 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '92', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_93,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '93', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_94,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '94', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_95,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 700', '64 900', '90 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '95', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_96,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '96', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_97,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '97', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_98,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '98', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_99,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '99', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_100,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '100', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_101,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '101', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_102,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '102', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_103,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '103', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_104,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '104', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_105,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['53 000', '68 800', '94 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '105', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_106,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 000', '66 300', '90 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '106', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_107,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '107', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_108,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '108', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_109,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 100', '61 200', '92 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '109', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_110,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '110', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_111,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '111', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_112,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '112', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_113,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '113', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_114,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 000', '66 300', '90 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '114', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_115,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['52 000', '66 300', '90 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '115', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_116,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '116', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_117,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['53 100', '68 800', '94 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '117', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_118,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['56 800', '72 300', '99 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '118', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_119,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '119', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_120,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '120', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_121,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '121', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_122,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['53 100', '68 800', '94 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '122', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_123,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '123', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_124,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['53 100', '68 800', '94 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '124', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_125,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['53 100', '68 800', '94 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '125', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_126,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 900', '65 100', '89 000'], // цена  
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '126', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_127,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['53 100', '68 800', '94 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '127', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_128,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['50 800', '64 900', '90 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '128', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_129,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['53 200', '68 800', '95 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '129', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_130,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '130', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_131,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '131', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_132,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '132', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_133,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '133', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_134,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['48 400', '65 100', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '134', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_135,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['53 200', '68 800', '95 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '135', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_136,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '136', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_137,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '137', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_138,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '138', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_139,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '139', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_140,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '140', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_141,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '141', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_142,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '142', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_143,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '143', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_144,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '144', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_145,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '145', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_146,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '146', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_147,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '147', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_148,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '148', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_149,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '149', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_150,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '150', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_151,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['61 500', '80 600', '105 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '151', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_152,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['63 800', '84 300', '108 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '152', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_153,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '153', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_154,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '154', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_155,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '155', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_156,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '156', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_157,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '157', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_158,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['63 800', '84 300', '108 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '158', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: gvert_159,
            title: 'Памятник гранитный вертикальный',
            description: 'Памятник из гранита вертикальный',
            price: ['60 900', '79 500', '103 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'vgranit',
            id: '159', // не трогать
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
        ]
      },
      {
        subcategory: 'children', // Гранит детские
        subtext: 'Детские',
        items: [ // В этот массив вставляется вся информация по памятнику
          {
            material: 'granit',
            count: 1,
            img: kids_1,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '1',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_2,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '2',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_3,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '3',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_4,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '4',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_5,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '5',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_6,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '6',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_7,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '7',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_8,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '8',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_9,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '9',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_10,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '10',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_11,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '11',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_12,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '12',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_13,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '13',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_14,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '14',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_15,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '15',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_16,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '16',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_17,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '17',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_18,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['43 100', '48 400', '62 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '18',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_19,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['37 500', '42 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '19',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_20,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['56 800'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '20',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_21,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['66 200'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '21',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_22,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['56 800'], // цена
            sizes: [{
              size: '60*60*8',
              tumb: '60*15*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '22',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_23,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['56 800'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '23',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_24,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['52 000'], // цена
            sizes: [{
              size: '60*60*8',
              tumb: '60*15*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '24',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_25,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['56 800'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '25',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_26,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['48 400'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '26',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_27,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['48 400'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '27',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_28,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['54 500'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '28',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_29,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['59 300', '77 100'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '29',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_30,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['59 300', '77 100'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '30',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: kids_31,
            title: 'Памятник детский гранитный',
            description: 'Памятник из гранита детский',
            price: ['48 400', '62 500'], // цена
            sizes: [{
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'children',
            id: '31',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
        ]
      },
      {
        subcategory: 'ggranit', // Гранит горизонтальный
        subtext: 'Горизонтальный',
        items: [ // В этот массив вставляется вся информация по памятнику
          {
            material: 'granit',
            count: 1,
            img: ggoriz_1,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['55 700', '63 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '1',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_2,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['60 100', '72 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '2',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_3,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['58 000', '71 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '3',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_4,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '4',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_5,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '5',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_6,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['58 000', '71 000'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '6',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_7,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['52 000', '71 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '7',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_8,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '8',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_9,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '9',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_10,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '10',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_11,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '11',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_12,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 300', '73 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '12',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_13,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '13',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_14,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '14',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_15,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '15',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_16,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['68 900', '85 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '16',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_17,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 300', '73 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '17',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_18,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 300', '73 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '18',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_19,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 300', '73 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '19',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_20,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['55 700', '63 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '20',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_21,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['55 700', '63 900'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '21',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_22,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '22',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_23,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['52 000', '71 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '23',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_24,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['56 800', '71 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '24',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_25,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['52 000', '71 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '25',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_26,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['95 500', '77 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '120*20*20'
            }, {
              size: '120*60',
              tumb: '70*25*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '26',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_27,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['97 700', '79 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '120*20*20'
            }, {
              size: '120*60',
              tumb: '70*25*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '27',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_28,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['82 200', '70 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '120*20*20'
            }, {
              size: '120*60',
              tumb: '70*25*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '28',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_29,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита без портрета',
            price: ['83 600', '71 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '120*20*20'
            }, {
              size: '120*60',
              tumb: '70*25*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '29',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник, без портрета</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_30,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['61 800', '76 600', '101 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '30',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_31,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['88 100', '76 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '120*20*20'
            }, {
              size: '120*60',
              tumb: '70*25*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '31',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_32,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['89 200', '77 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '120*20*20'
            }, {
              size: '120*60',
              tumb: '70*25*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '32',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_33,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['66 800', '69 100'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '120*20*20'
            }, {
              size: '120*60',
              tumb: '70*25*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '33',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_34,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['66 700', '82 500'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '34',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_35,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '35',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_36,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['57 500', '70 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '36',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_37,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '37',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_38,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 700', '84 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '38',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_39,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '39',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_40,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['66 900', '82 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '40',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_41,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['64 200', '79 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '41',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_42,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['57 500', '70 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '42',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_43,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['57 500', '70 400'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '43',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_44,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '44',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_45,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['66 800', '82 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '45',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_46,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '46',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_47,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['66 800', '82 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '47',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_48,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['66 800', '82 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '48',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_49,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['66 800', '82 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '49',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_50,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '50',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_51,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '51',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_52,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '52',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_53,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '53',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_54,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '54',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_55,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '55',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_56,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '56',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_57,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['61 800', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }, {
              size: '150*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '57',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_58,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '58',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_59,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '59',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_60,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '60',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_61,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['64 200', '79 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '61',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_62,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '62',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_63,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['71 500', '84 800'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '63',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_64,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '64',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_65,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '65',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_66,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '66',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_67,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '67',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_68,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '68',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_69,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '69',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_70,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '70',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_71,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '71',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_72,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '72',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_73,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '73',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_74,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '74',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_75,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '75',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_76,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '76',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_77,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['59 600', '72 700'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '77',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_78,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '78',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_79,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '79',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_80,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['74 000', '87 300'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '80',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: ggoriz_81,
            title: 'Памятники из гранита горизонтальный',
            description: 'Памятник из гранита',
            price: ['62 000', '75 200'], // цена
            sizes: [{
              size: '100*50*8',
              tumb: '60*15*20'
            }, {
              size: '120*60',
              tumb: '70*20*20'
            }], // размеры
            active: 0,
            category: 'ggranit',
            id: '81',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
        ]
      }
    ]
  },
  {
    category: 'mramor', // Мрамор
    text: 'Мрамор',
    monuments: [{
        subcategory: 'vmramor', // Мрамор вертикальный
        subtext: 'Вертикальный',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'mramor',
            count: 1,
            img: mvert_1,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['19 800'], // цена
            sizes: [{
              size: '60*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '1',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_2,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный без фото',
            price: ['19 500'], // цена
            sizes: [{
              size: '60*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '2',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник без фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_3,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['21 600'], // цена
            sizes: [{
              size: '60*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '3',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_4,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный без фото',
            price: ['19 200'], // цена
            sizes: [{
              size: '60*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '4',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник без фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_5,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['20 400'], // цена
            sizes: [{
              size: '60*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '5',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_6,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный, без фото, открытый',
            price: ['24 000'], // цена
            sizes: [{
              size: '80*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '6',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник открытый без фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_7,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['25 000'], // цена
            sizes: [{
              size: '80*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '7',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_8,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['23 800'], // цена
            sizes: [{
              size: '80*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '8',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_9,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['23 800'], // цена
            sizes: [{
              size: '80*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '9',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_10,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['24 100'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '10',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_11,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['25 200'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '11',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_12,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['25 200'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '12',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_13,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['25 200'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '13',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_14,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['25 200'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '14',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_15,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 700'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '15',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_16,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 500'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '16',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_17,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный фото ЧБ',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '17',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник имеет фото ЧБ</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_18,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '18',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_19,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '19',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_20,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['28 400'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '20',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_21,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['25 700'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '21',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_22,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный фото ЧБ',
            price: ['27 500'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '22',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник имеет фото ЧБ</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_23,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '23',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_24,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный фото 20*30',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '24',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник имеет фото 20*30</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_25,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный на 2х человек, фото ЧБ',
            price: ['60 800'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '25',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник на 2х человек фото ЧБ</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_26,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['30 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '26',
            prop: `
              <ul type="square">ы
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_27,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['26 700'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '27',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_28,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['26 900'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '28',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_29,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '29',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_30,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['28 800'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '30',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_31,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 600'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '31',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_32,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['30 600'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '32',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_33,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный на 2х человек',
            price: ['60 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '33',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_34,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный без фото',
            price: ['31 600'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '34',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник без фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_35,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['33 500', '36 500'], // цена
            sizes: [{
              size: '120*50',
              tumb: 'Без фото'
            }, {
              size: '120*50',
              tumb: 'С фото'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '35',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_36,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['30 600'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '36',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_37,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['35 000'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '37',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_38,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный без фото',
            price: ['27 000'], // цена
            sizes: [{
              size: '120*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '38',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник без фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_39,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['29 000'], // цена
            sizes: [{
              size: '120*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '39',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_40,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['31 800'], // цена
            sizes: [{
              size: '120*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '40',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_41,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['40 600'], // цена
            sizes: [{
              size: '120*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '41',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_42,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['31 400'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '42',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_43,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['30 800'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '43',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_44,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['31 400'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '44',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_45,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['32 400'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '45',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_46,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['33 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '46',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_47,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 800'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '47',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_48,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный фото 20*30', 
            price: ['30 300'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '48',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_49,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '49',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_50,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['32 600'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '50',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_51,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['32 900'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '51',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_52,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['32 600'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '52',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_53,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['33 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '53',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_54,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['32 600'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '54',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_55,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['33 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '55',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_56,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['33 800'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '56',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_57,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['33 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '57',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_58,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['30 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '58',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_59,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['31 400'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '59',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_60,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['32 600'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '60',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_61,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['28 500'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '61',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_62,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['31 000'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '62',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_63,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный фото ЧБ',
            price: ['28 800'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '63',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник имеет Фото ЧБ</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_64,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['30 600'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '64',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_65,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный на 2х человек',
            price: ['40 000'], // цена
            sizes: [{
              size: '120*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '65',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник на 2х человек</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_66,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный фото овал',
            price: ['40 500'], // цена
            sizes: [{
              size: '120*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '66',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                  <li>Памятник имеет фото овал</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_67,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['28 100'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '67',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_68,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный без фото',
            price: ['37 100'], // цена
            sizes: [{
              size: '120*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '68',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник без фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_69,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['41 700'], // цена
            sizes: [{
              size: '100*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '69',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_70,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный без фото',
            price: ['29 100'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '70',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_71,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['26 300'], // цена
            sizes: [{
              size: '80*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '71',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_72,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный без фото',
            price: ['28 200'], // цена
            sizes: [{
              size: '80*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '72',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник без фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_73,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный без фото',
            price: ['25 000'], // цена
            sizes: [{
              size: '80*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '73',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник без фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_74,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 600'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '74',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_75,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '75',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_76,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '76',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_77,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 000'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '27',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_78,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['27 900'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '78',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_79,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['28 500'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '79',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_80,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['32 400'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '80',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_81,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['28 200'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '81',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_82,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный фото 40*50,сердце',
            price: ['35 800'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '82',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник имеет фото 40*50,сердце</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_83,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['26 900'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '83',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_84,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный с фото 20*30 овал',
            price: ['32 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '84',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник имеет фото 20*30 овал</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'mramor',
            count: 1,
            img: mvert_85,
            title: 'Памятник Мраморный вертикальный',
            description: 'Памятник из мрамора вертикальный',
            price: ['24 800'], // цена
            sizes: [{
              size: '80*45',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'vmramor',
            id: '85',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },

        ]
      },
      {
        subcategory: 'gmramor', // Мрамор горизонтальный
        subtext: 'Горизонтальный',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: mgoriz_1,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный, 2 портрета, 1 цветник',
            price: ['32 800'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '86',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник имеет два портрета</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_2,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный,без фото, 2 кубика',
            price: ['29 400'], // цена
            sizes: [{
              size: '100*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '87',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_3,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['32 700'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '89',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_4,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['32 800'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '90',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_5,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['33 300'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '91',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_6,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный + н.п',
            price: ['38 500'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '92',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_7,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный + н.п',
            price: ['38 500'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '93',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_8,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный 1комплект',
            price: ['41 600'], // цена
            sizes: [{
              size: '120*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '94',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_9,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный 2 цветника',
            price: ['36 700'], // цена
            sizes: [{
              size: '120*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '95',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет два цветника</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_10,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['38 400'], // цена
            sizes: [{
              size: '120*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '96',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_11,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный увеличеный цветник',
            price: ['31 300'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '97',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет увеличенный цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_12,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['30 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '98',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_13,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['30 800'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '99',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_14,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['33 400'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '100',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_15,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['31 300'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '101',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_16,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['31 300'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '102',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_17,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['31 300'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '103',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_18,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['30 800'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '104',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_19,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный + н.п',
            price: ['30 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '105',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_21,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный с фото',
            price: ['30 200'], // цена
            sizes: [{
              size: '120*60',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '106',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Памятник имеет фото</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_22,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['30 300'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '107',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_23,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['30 200'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '108',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },
          {
            material: 'granit',
            count: 1,
            img: mgoriz_24,
            title: 'Памятник мрамор горизонтальный',
            description: 'Памятник из мрамора горизонтальный',
            price: ['31 300'], // цена
            sizes: [{
              size: '120*50',
              tumb: '-------'
            }], // размеры
            active: 0,
            category: 'gmramor',
            id: '109',
            prop: `
              <ul type="square">
                <li>Размеры:</li>
                <li>Памятник #</li>
                <li>Тумба *</li>
                <li>Памятник имеет цветник</li>
                <li>Цена указана за готовый памятник</li>
                <li>Мы можем изготовить памятник в любом цвете</li>
                <li>Оплата после установки памятника</li>
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }
        ]
      },
    ]
  },
  {
    category: '3d', // Прочее
    text: '3d',
    monuments: [{
        subcategory: '3d', // 3d работы
        subtext: '3d работы',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'mramor',
            count: 1,
            img: threed_1,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '1',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_2,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '2',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_3,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '3',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_4,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '4',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_5,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '5',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_6,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '6',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_7,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '7',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_8,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '8',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_9,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '9',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_10,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '10',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_11,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '11',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_12,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '12',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_13,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '13',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_14,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '14',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_15,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '15',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_16,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '16',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_17,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '17',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_18,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '18',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_19,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '19',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_20,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '20',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_21,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '21',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_22,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '22',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_23,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '23',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_24,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '24',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_25,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '25',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_26,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '26',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_27,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '27',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_28,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '28',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_29,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '29',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_30,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '30',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_31,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '31',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_32,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '32',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_33,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '33',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_34,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '34',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_35,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '35',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_36,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '36',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_37,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '37',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_38,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '38',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_39,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '39',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_40,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '40',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'mramor',
            count: 1,
            img: threed_41,
            title: '3d работа',
            description: 'В ознакомительных целях, не для заказа.',
            price: ['0'], // цена
            sizes: [], // размеры
            active: 0,
            category: '3d',
            id: '41',
            prop: `
              <ul type="square">
                <li>В ознакомительных целях</li>
                <li>Не для заказа.</li>с
              </ul>
            `,
            info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },  
        ]
      },  
    ]
  },
  {
    category: 'works', // Прочее
    text: 'Наши работы',
    monuments: [
      {
        subcategory: 'works', // Наши работы
        subtext: 'Наши работы',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: works_1,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_2,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_3,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '3',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_4,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '4',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_5,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '5',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_6,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '6',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_7,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '7',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_8,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '8',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_9,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '9',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_10,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '10',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_11,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '11',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_12,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '12',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_13,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '13',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_14,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '14',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_15,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '15',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_16,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '16',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_17,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '17',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_18,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '18',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_19,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '19',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_20,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '20',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_21,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '21',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_22,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '22',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_23,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '23',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_24,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '24',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_25,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '25',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_26,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '26',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_27,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '27',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_28,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '28',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_29,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '29',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_30,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '30',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_31,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '31',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_32,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '32',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_33,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '33',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_34,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '34',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_35,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '35',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          }, 
          {
            material: 'granit',
            count: 1,
            img: works_36,
            title: 'Наши работы',
            description: 'Представленыые работы изготовление и установлены нашими мастерами.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'works',
            id: '36',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },   
        ]
      }, 
    ]
  },
  {
    category: 'acsessuar', // Прочее
    text: 'Аксессуары',
    monuments: [
      {
        subcategory: 'balls', // Наши работы
        subtext: 'Шары',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: acssesuar_balls_1,
            title: 'Шар',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'balls',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: acssesuar_balls_2,
            title: 'Шар',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'balls',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: acssesuar_balls_3,
            title: 'Шар',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'balls',
            id: '3',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: acssesuar_balls_4,
            title: 'Шар',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'balls',
            id: '4',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: acssesuar_balls_5,
            title: 'Шар',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'balls',
            id: '5',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },  
          {
            material: 'granit',
            count: 1,
            img: acssesuar_balls_6,
            title: 'Шар',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'balls',
            id: '6',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },  
        ]
      }, 
      {
        subcategory: 'fences', // Наши работы
        subtext: 'Заборы',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: acssesuar_fences_1,
            title: 'Забор',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'fences',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },   
          {
            material: 'granit',
            count: 1,
            img: acssesuar_fences_2,
            title: 'Забор',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'fences',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },   
          {
            material: 'granit',
            count: 1,
            img: acssesuar_fences_3,
            title: 'Забор',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'fences',
            id: '3',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },   
          {
            material: 'granit',
            count: 1,
            img: acssesuar_fences_4,
            title: 'Забор',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'fences',
            id: '4',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },   
          {
            material: 'granit',
            count: 1,
            img: acssesuar_fences_5,
            title: 'Забор',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'fences',
            id: '5',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },   
        ]
      }, 
      {
        subcategory: 'granitetille', // Наши работы
        subtext: 'Гранитная плитка',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: acssesuar_granitetile_1,
            title: 'Гранитная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'granitetille',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },    
          {
            material: 'granit',
            count: 1,
            img: acssesuar_granitetile_2,
            title: 'Гранитная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'granitetille',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },    
          {
            material: 'granit',
            count: 1,
            img: acssesuar_granitetile_3,
            title: 'Гранитная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'granitetille',
            id: '3',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },    
          {
            material: 'granit',
            count: 1,
            img: acssesuar_granitetile_4,
            title: 'Гранитная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'granitetille',
            id: '4',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },    
          {
            material: 'granit',
            count: 1,
            img: acssesuar_granitetile_5,
            title: 'Гранитная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'granitetille',
            id: '5',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },    
          {
            material: 'granit',
            count: 1,
            img: acssesuar_granitetile_6,
            title: 'Гранитная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'granitetille',
            id: '6',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },    
          {
            material: 'granit',
            count: 1,
            img: acssesuar_granitetile_7,
            title: 'Гранитная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'granitetille',
            id: '7',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },    
        ]
      }, 
      {
        subcategory: 'lamps', // Наши работы
        subtext: 'Лампы',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: acssesuar_lamps_1,
            title: 'Лампы',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'lamps',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },     
          {
            material: 'granit',
            count: 1,
            img: acssesuar_lamps_2,
            title: 'Лампы',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'lamps',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },     
          {
            material: 'granit',
            count: 1,
            img: acssesuar_lamps_3,
            title: 'Лампы',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'lamps',
            id: '3',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },     
          {
            material: 'granit',
            count: 1,
            img: acssesuar_lamps_4,
            title: 'Лампы',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'lamps',
            id: '4',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },     
          {
            material: 'granit',
            count: 1,
            img: acssesuar_lamps_5,
            title: 'Лампы',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'lamps',
            id: '5',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },     
          {
            material: 'granit',
            count: 1,
            img: acssesuar_lamps_6,
            title: 'Лампы',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'lamps',
            id: '6',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },     
          {
            material: 'granit',
            count: 1,
            img: acssesuar_lamps_7,
            title: 'Лампы',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'lamps',
            id: '7',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },     
          {
            material: 'granit',
            count: 1,
            img: acssesuar_lamps_8,
            title: 'Лампы',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'lamps',
            id: '8',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },     
        ]
      }, 
      {
        subcategory: 'pavingtile', // Наши работы
        subtext: 'Керамогранит',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: acssesuar_pavingtile_1,
            title: 'Керамогранит',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'pavingtile',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_pavingtile_2,
            title: 'Керамогранит',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'pavingtile',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_pavingtile_3,
            title: 'Керамогранит',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'pavingtile',
            id: '3',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_pavingtile_4,
            title: 'Керамогранит',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'pavingtile',
            id: '4',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_pavingtile_5,
            title: 'Керамогранит',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'pavingtile',
            id: '5',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_pavingtile_6,
            title: 'Керамогранит', 
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'pavingtile',
            id: '6',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
        ]
      }, 
      {
        subcategory: 'porcelaintile', // Наши работы
        subtext: 'Тротуарная плитка',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: acssesuar_porcelaintile_1,
            title: 'Тротуарная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'porcelaintile',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },      
          {
            material: 'granit',
            count: 1,
            img: acssesuar_porcelaintile_2,
            title: 'Тротуарная плитка',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'porcelaintile',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },      
        ]
      }, 
      {
        subcategory: 'vases', // Наши работы
        subtext: 'Вазы',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_1,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_2,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_3,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '3',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_4,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '4',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_5,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '5',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_6,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '6',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_7,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '7',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_8,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '8',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_9,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '9',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_10,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '10',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_11,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '11',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_12,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '12',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_13,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '13',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_14,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '14',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_15,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '15',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_16,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '16',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_17,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '17',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_18,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '18',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_19,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '19',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_20,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '20',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_21,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '21',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_22,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '22',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
          {
            material: 'granit',
            count: 1,
            img: acssesuar_vases_23,
            title: 'Ваза',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'vases',
            id: '23',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },        
        ]
      }, 
      {
        subcategory: 'other', // Наши работы
        subtext: 'Прочее',
        items: [ // В этот массив вставляется вся информация по памятнику 
          {
            material: 'granit',
            count: 1,
            img: acssesuar_other_1,
            title: 'Прочее',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'other',
            id: '1',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_other_2,
            title: 'Прочее',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'other',
            id: '2',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_other_3,
            title: 'Прочее',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'other',
            id: '3',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_other_4,
            title: 'Прочее',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'other',
            id: '4',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_other_5,
            title: 'Прочее',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'other',
            id: '5',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_other_6,
            title: 'Прочее',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'other',
            id: '6',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
          {
            material: 'granit',
            count: 1,
            img: acssesuar_other_7,
            title: 'Прочее',
            description: 'Аксессуары.',
            price: [0], // цена
            sizes: [], // размеры
            active: 0,
            category: 'other',
            id: '7',
            prop: `
            <ul type="square">
              <li>В ознакомительных целях</li>
              <li>Не для заказа.</li>с
            </ul>
          `,
          info: `
            <div>
              <h1>Дополнительную информацию можно уточнить по телефону:</h1>
              <h2><a href=tel:${telephone}>${telephoneart}</a></h2>
            </div>        
          `
          },       
        ]
      }, 
    ]
  },
]
 
const about_plus = [
  {
    plus: 'Своевременную бесплатную консультацию по любому вопросу.'
  },
  {
    plus: 'Гарантию 15 лет на памятники категории вип.'
  },
  {
    plus: 'Качество продукции и материалов.'
  },
]

const about_product = {
    img: images.a1,
    text: `
      
      Род деятельности компании — изготовление и установка памятников на могилу.
      Мы на рынке ритуальных услуг уже более 15 лет. 
      Более 1000 клиентов в год доверяют нам в камне увековечить память о своих близких.
      Производство компании находится в г. Майкоп, ст. Ханская, но для замера и установки надгробного памятника на могилу команда наших профессионалов выезжает в любую точку Краснодарского края и Республики Адыгея. 
      Поэтому вы можете заказать памятник в Краснодаре, заказать памятник в Белореченске, в Кропоткине, в Сочи, в Абинскеи в любом другом городе и населенном пункте края и республики.
    `,
    img_2: images.a2,
    text_2: `
      Мы работаем без посредников  — вы оплачиваете без наценок только стоимость материалов и выполненных работ и только после полного их завершения. 
      Мы выполняем как стандартные бюджетные заказы, так и эксклюзивные элитные дизайнерские проекты. 
      Поэтому вы можете купить памятник по доступной цене из каталога, или воплотить свои идеи с бо́льшим бюджетом руками наших мастеров. 
      Мы понимаем, в какой трудный период жизни вы обращаетесь за нашими услугами, поэтому прикладываем все силы, 
      чтобы сотрудничество с нами прошло для вас без дополнительных переживаний.
    `
}

const ordedr_work = [
  {
    title: 'Заявка',
    description: `
      Вы оставляете заявку :
      — заполнив любую форму
      на сайте, — по телефону,
      — или в одном из офисов продаж.
    `
  },
  {
    title: 'Договор',
    description: `
    Мы заключаем договор
    `
  },
  {
    title: 'Изготовление',
    description: `
    Мы изготавливаем ваш памятник
    `
  },
  {
    title: 'Доставка',
    description: `
    мы отправляем ваш заказ собственным транспортом или же транспортными компаниями
    `
  },
  {
    title: 'Установка',
    description: `
    Мы устанавливаем ваш памятник
    `
  },
  {
    title: 'Оплата',
    description: `
    Вы принимаете работу и оплачиваете остаток
    `
  },
]

const feedback = [
  {
    name:'Нина',
    sub_name:'Филиповна',
    text:'Оказали всё как подобает.',
    star: 3
  },
  {
    name:'Олег',
    sub_name:'Петрович',
    text:'Отличное качество,персонал и обслуживание.',
    star: 5
  },
  {
    name:'Эдуард',
    sub_name:'Святославович',
    text:'В целом всё оказалось приемлемо.',
    star: 2
  },
  {
    name:'Ольга',
    sub_name:'Алексеевна',
    text:'Сделали скидку, а всего лишь необходимо было заказать в первые 15 минут!',
    star: 4
  },
  {
    name:'Юлия',
    sub_name:'Викторовна',
    text:'Огромный выбор и отличный персонал',
    star: 1
  },
  {
    name:'Имсаил',
    sub_name:'Исмаилович',
    text:'Попросили оставить отзыв.',
    star: 0
  },
]

const answers = [
  {
    request: `Что выбрать гранит или мрамор?`,
    response: `Мрамор
    Это осадочная горная порода из карбоната кальция и магния. Мрамор имеет рисунок в виде разводов и прожилок. Чаще встречается светлый мрамор, но благодаря примесям, входящим в состав камня, он может быть разных оттенков от желтого до черного.
    Мрамор обладает большей пластичностью, благодаря чему его поверхность лучше поддаётся механической обработке – резке и полировке. Из-за высокой плотности мрамор практически не поглощает влагу, что придаёт ему высокую морозостойкость и устойчивость к ударам, растрескиванию.
    Гранит
    Это вулканическая горная порода, образующаяся в результате остывания и окаменения магмы. Гранит имеет зернистый рисунок цветом от розового до зеленого и даже черного.
    Однако плотность гранита более чем в два раза превышает таковую
    у мрамора. Так, средний срок службы мрамора на открытом воздухе
    в условиях нашего климата 110-160 лет, а срок службы гранита — 500-600 лет
    Делая выбор между гранитом и мрамором для изготовления памятника, учитывайте
    Разницу в прочности Это два очень прочных материала, но гранит более долговечен
    Свой вкус Какой рисунок и оттенок вам придется по душе
    Свой бюджет
    Гранит сложнее добывать
    и тяжелее поддается обработке, поэтому стоит он дороже. Мрамор же доступен по цене практически любой семье.
    `
  },
  {
    request: `Как сделать заказ?`,
    response: `Заявка
    Вы оставляете заявку : — заполнив любую форму на сайте, — по телефону, — или в одном из офисов продаж.
    Договор
    После согласования всех деталей с менеджером мы составляем
    и подписываем договор
    Изготовление
    Стандартный памятник изготавливаем от 3 до 14 дней. Мемориальный комплекс — от 1 месяца
    Установка
    Доставляем
    и устанавливаем памятник на могилу в удобное
    для вас время. Подробнее
    Оплата
    Вы оплачиваете стоимость памятника
    и всех работ нашему сотруднику на месте после установки
    3.  доставка
     Мы доставим и установим памятник на могилу в любую точку Краснодарского края и Республики Адыгея.
    ► Мы доставим и установим памятник по согласованию
    в удобное для вас время.
    ► За 15 лет работы мы доставили и установили памятники
    в городах: Абинск, Анапа, Геленжик, Апширонск, Белореченск, Ейск, Гулькевичи, Коренвск, Крымск, Курганинск, Лабинск, Майкоп, Новокубанск, Приморско-Ахтарск, Сочи, Темрюк, Тихорецк, Тимошевск, Туапсе, Усть-Лабинск, в станицах
    и селах: Выселковская, Белая глина, Динская, Брюховецкая, Каневская, Абрау-Дюрсо, Новомышавтовская, Лазоревская, Кущевская и во многих других городах и населенных пунктах нашего края и республики.
    ► Если потребуется (например, для установки мемориальных комплексов), мы выезжаем несколько раз.
    `
  },
  {
    request: `Как оплатить?`,
    response: `Наличными и безналичный расчёт.`
  },
]
 
const priceDelivery = 1200;

const arrayMaterial = [[0,0,0],[9000,19000,42500],[12000,24000,50000],[12000,25500,45500],[15000,30000,53000],[15000,31000,'По запросу'],[17000,	35000,'По запросу'],[18000,37000,'По запросу'],[21000,42000,'По запросу'],[19500,47000,'По запросу'],[24500,54000,'По запросу'],[22000,52000,'По запросу'],[28000,58000,'По запросу'],[37000,81000,'По запросу'],[47500,93000,'По запросу']]

export { 
  about_plus,
  about_product,
  ordedr_work,
  feedback,
  answers,
  products, 
  priceDelivery,
  API_URL,
  arrayMaterial,
  telephone
}