import telega from  './images/telega.png';    
import phone from  './images/phone.svg';   
import around from  './images/around.svg';   
import arrow from  './images/arrow.svg';   
import arrowl from  './images/arrowl.png';   
import arrowr from  './images/arrowr.png';   
import arrowf from  './images/arrowf.svg';   
import arrowup from  './images/arrowup.svg';   
import cross from  './images/cross.svg';   
import crossp from  './images/cross.png';   
import poster from  './images/poster.png'
import time from  './images/time.svg';   
import back from  './images/back.svg';         
import mastercard from  './images/mastercard.svg';         
import local from  './images/loal.svg';         
import mir from  './images/mir.svg';         
import sber from  './images/sber.svg';         
import monument from  './images/monument.jpg';         
import monumentgif from  './images/monument.gif';         
import rassrochka from  './images/rassrochka.gif';         
import tel_mob from  './images/tel_mob.gif';         
import ustanovka from  './images/ustanovka.gif';         
import tel from  './images/tel.gif';         
import aroundtwo from  './images/aroundtwo.png';   
import maket from  './images/macet.png';   
import stoneGif from  './images/3dstone.mp4';      
import proiz_1 from  './images/proiz_0.jpg';   
import proiz_2 from  './images/proiz_1.jpg';    
import proiz_3 from  './images/proiz_2.jpg';    
import proiz_4 from  './images/proiz_3.jpg';    
import proiz_5 from  './images/proiz_4.jpg';    
import view_1 from  './images/view_1.png';
import view_2 from  './images/view_2.png'; 
import view_3 from  './images/view_3.png';
import view_4 from  './images/view_4.png';
import view_5 from  './images/view_5.png'; 
import view_6 from  './images/view_6.png'; 
import view_7 from  './images/view_7.png';
import view_8 from  './images/view_8.png'; 
import view_9 from  './images/view_9.png';
import view_10 from  './images/view_10.png'; 
import view_11 from  './images/view_11.png';
import view_12 from  './images/view_12.png';
import view_13 from  './images/view_13.png';
import slide_0 from  './images/slide_0.jpg';
import slide_1 from  './images/slide_1.jpg';
import slide_2 from  './images/slide_2.jpg'; 
import slide_3 from  './images/slide_3.webp';
import slide_4 from  './images/slide_4.jpg';
import slide_5 from  './images/slide_5.jpg'; 
import slide_6 from  './images/slide_6.jpg'; 
import slide_7 from  './images/slide_7.jpg';
import slide_8 from  './images/slide_8.jpeg'; 
import slide_9 from  './images/slide_9.jpg';
import slide_10 from  './images/slide_10.jpg';  
import icont from  './images/icont.png';  
import iconp from  './images/iconp.png';   
 
// eslint-disable-next-line import/no-anonymous-default-export
 
export default {  
  telega, 
  around, 
  arrow, 
  arrowr, 
  arrowl, 
  aroundtwo,
  poster,
  back,
  arrowf,
  cross, 
  mastercard,
  mir,
  sber,
  monument,
  monumentgif,
  rassrochka,
  tel_mob,
  ustanovka,
  tel,
  stoneGif, 
  local,
  proiz_1,
  proiz_2,
  proiz_3,
  proiz_4,
  proiz_5,
  maket,
  phone,
  time,
  view_1,
  view_2,
  view_3,
  view_4,
  view_5,
  view_6,
  view_7,
  view_8,
  view_9,
  view_10, 
  view_11,  
  view_12,  
  view_13,  
  slide_0,
  slide_1,
  slide_2,
  slide_3,
  slide_4,
  slide_5,
  slide_6,
  slide_7,
  slide_8,
  slide_9,
  slide_10,   
  iconp,
  arrowup, 
  icont,
  crossp
}
